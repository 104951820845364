import $ from 'cash-dom'

let links = $('a.depart')
let roles = $('div.role')

//Hide department's links without roles
for (let i = 1; i < links.length; i++) {
  let slectedRoles = $('div.' + links[i].id)

  if (slectedRoles.length == 0) {
    $('#' + links[i].id)
      .parent()
      .addClass('hidden')
  } else {
    $('#' + links[i].id)
      .parent()
      .removeClass('hidden')
  }
}
if (window.matchMedia('(max-width:550px)').matches) {
  links.removeClass('active')
}

function showRoles(targetedLink) {
  //Remove active style from links
  links.removeClass('active')
  links.addClass('text-gray-500')
  roles.addClass('hidden')
  targetedLink.addClass('active')

  //Sow roles
  if (targetedLink.attr('id') == 'all') {
    roles.removeClass('hidden')
  } else {
    let slectedRoles = $('div.' + targetedLink.attr('id'))
    slectedRoles.removeClass('hidden')
  }
}

//Show and hide mobile panel
function toggleMobilePanel(query) {
  let positionTop =
    document.querySelector('#department').getBoundingClientRect().y + 'px'

  if (window.matchMedia('(' + query + ')').matches) {
    $('#mobilePanel').css('display', 'block') //Show mobile panel
    $('#mobilePanel').css('top', '60px')
  } else {
    $('#mobilePanel').css('display', 'none') //Hide
  }
}

//Hide mobile panel on click
$('#close').on('click', (e) => {
  e.preventDefault()
  links.removeClass('active')

  //Hide
  toggleMobilePanel('min-width:900px')
})

//Filter roles by department
links.on('click', (e) => {
  e.preventDefault()

  let targetedLink = $(e.target)
  showRoles(targetedLink)

  //Display roles mobile
  if (window.matchMedia('(max-width:550px)').matches) {
    //Replace role for department name
    $('#roleHeadline').html(targetedLink.html())

    //Show panel with roles
    toggleMobilePanel('max-width:550px')
  }
})
