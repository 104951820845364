const showStickyBar = (stickyBar, position) => {
  if (window.scrollY >= position) {
    stickyBar.style.display = 'block'
  } else {
    stickyBar.style.display = 'none'
  }
}

if (
  Pura.template.suffix === 'diffuser-v4' ||
  Pura.template.suffix === 'diffuser-v3' ||
  Pura.template.suffix === 'car-pdp'
) {
  setTimeout(() => {
    const stickyBar = document.getElementById('pdp-sticky-bar')
    const newLayout = document.getElementById('new-device-layout')

    // Function to check visibility
    const isVisible = (elem) =>
      elem && window.getComputedStyle(elem).display !== 'none'

    // Directly work with newLayout if it is visible
    if (isVisible(newLayout)) {
      const bysCTA = newLayout.querySelector('#build-your-set-cta')

      if (stickyBar && bysCTA) {
        const updateStickyBarPosition = () => {
          const distanceFromTop =
            bysCTA.getBoundingClientRect().top + window.scrollY
          showStickyBar(stickyBar, distanceFromTop)
        }

        // Call once on load in case we're already past the position
        updateStickyBarPosition()

        // Update sticky bar position on scroll
        window.onscroll = updateStickyBarPosition
      }
    }
  }, 2000)
}
