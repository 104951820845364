import $ from 'cash-dom'

let page = 2
const showLess = $('#show-less')
const viewMore = $('#view-more')
const articleWrapper = $('#article-wrapper')

const updateArticles = () => {
    const totalPages = parseInt($('[data-total-pages]').val(), 10)
		const nextUrl = $('[data-next-url]').val().replace(/page=\d+/, `page=${page}`);

    fetch(nextUrl)
        .then(response => response.text())
        .then(html => {
            const parser = new DOMParser()
            const doc = parser.parseFromString(html, 'text/html')
            const articles = doc.querySelectorAll('#article')
            articles.forEach(article => {
                articleWrapper.append(article.outerHTML)
            })
            page++

            if (page > totalPages) {
                viewMore.addClass('hidden')
                showLess.removeClass('hidden')
            }
        })
        .catch(error => console.error('Error loading articles:', error))
}

$('.js-load-more').on('click', () => {
    updateArticles()
    $(this).find('[loader]').addClass('hidden')
    $(this).find('[load-more-text]').removeClass('hidden')
})

$('.js-show-less').on('click', () => {
    page = 2
    articleWrapper.find('#article').slice(3).remove()
    viewMore.removeClass('hidden')
    showLess.addClass('hidden')
    articleWrapper.get(0).scrollIntoView()
})