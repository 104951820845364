import $ from "cash-dom";

$('[data-action="clear-text"]').on('click', function(e){
  e.preventDefault();
  let input = $(e.currentTarget).closest('[data-component="text-input"]').find('[type="text"]');
  input.val("")
  if (input.length > 0) { input[0].focus() }
});



