const getProductImage = (imageUrl) =>
  imageUrl ? imageUrl.replace('.jpg', '_200x.jpg') : ''

const gwpCartItem = (product) =>
  product
    ? `<div class="flex items-center w-full mx-1">
      <img src="${getProductImage(product.image)}"
        alt="${product.title}"
        width="70" height="85"
        loading="lazy"
        fetchpriority="low"
        class="object-cover" />
      <div class="ml-3">
        ${
          product.description
            ? `<p class="text-caption text-amber">${product.description}</p>`
            : ''
        }
      </div>
    </div>`
    : ''

export { gwpCartItem }
