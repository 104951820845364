import { renderCartItem } from '../templates/cart-item'
import { renderGroup } from '../templates/cart-group'

const generateCartItemsHTML = (cartData) => {
  const items = cartData.items
  let item, html = ""

  // loop through items in the cart
  for (let i=0, n=items.length; i < n; ++i) {
    item = items[i]

    // skip hidden and child items
    if (item.properties?._pura_hidden_in_cart == true ||
        item.properties?._pura_group_role == 'child'){
      continue
    }

    // render the template
    switch(getTemplateName(item)) {
      case 'group':
        html += renderGroup(item, items)
        break;
      default:
        html += renderCartItem(item)
    }
  }

  return html;
}



const getTemplateName = (item) => {
  // check to see if it's a bundle
  if (item.properties?._pura_group_id != null){
    return 'group'
  }
  return 'cart-item'
}



export { generateCartItemsHTML }
