import $ from 'cash-dom'

const buildReviews = () => {
  $('[data-action="review-count"]').each(function () {
    let count = $(this).html()
    if (count == 'undefined') {
      count = 0
    }
    const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    const reviewCount = numberWithCommas(count)
    $(this).html(reviewCount)
  })
}

if (window.location.hash === '#write-a-review') {
  window.addEventListener('load', () => {
    setTimeout(() => {
      $('.write-question-review-button').click()
    }, 1000)
  })
}

export { buildReviews }
