import $ from 'cash-dom'

if (Pura.template.name === 'product') {
  const totalReviews =
    Pura.product.metafields?.total_reviews?.toLocaleString() || false

  if (totalReviews) {
    const observer = new MutationObserver((mutations, obs) => {
      const element = document.querySelector('.reviews-qa-label')
      if (element && element.offsetParent !== null) {
        $('.reviews-qa-label').html(totalReviews + ' Reviews')
        clearTimeout(observerTimeout)
        obs.disconnect()
      }
    })

    observer.observe(document, {
      attributes: true,
      childList: true,
      subtree: true,
    })

    const observerTimeout = setTimeout(() => {
      observer.disconnect()
    }, 5000)
  }
}
