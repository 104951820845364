const getProductPricing = (price, productType) => {
  if (Pura.cart.enable_sitewide_discount) {
    const discountTypes = Pura.cart.discount_types
      ? Pura.cart.discount_types.split(', ')
      : false

    const siteWideAmount = (price * Pura.cart.discount_amount) / 100
    if (!discountTypes || discountTypes.includes(productType)) {
      price = price - siteWideAmount
    }
  }

  return price.toFixed(2)
}
const getCollectionProductPricing = (price, productType) => {
  if (
    Pura.cart.enable_sitewide_discount &&
    !Pura.BYS?.ignore_sitewide_discount &&
    !Pura.BYGS?.ignore_sitewide_discount &&
    Pura.product?.id !== 7113540337773
  ) {
    const discountTypes = Pura.cart.discount_types || false
    const siteWideAmount = (price * Pura.cart.discount_amount) / 100

    if (!discountTypes || discountTypes?.includes(productType)) {
      price = price - siteWideAmount
    }
  }

  return price.toFixed(2)
}
export { getProductPricing, getCollectionProductPricing }
