import $ from "cash-dom";

$('[data-action="filter-collection-carousel"]').change(function (e) {
  let url = this.getAttribute("data-collection-url");
  let button = $("#featured-collection-button");

  button.attr("href", url);
});

let collection_loading = "h-0 invisible opacity-0";

$('[data-filter-class="collection-2"]').click(function () {
  $('[data-action="slider_2"]').removeClass(collection_loading);
  $('[data-action="slider_1"]').addClass(collection_loading);
});

$('[data-filter-class="collection-1"]').click(function () {
  $('[data-action="slider_2"]').addClass(collection_loading);
  $('[data-action="slider_1"]').removeClass(collection_loading);
});
