import $ from "cash-dom";

document.addEventListener('click', e => {
  
  if (!e.target.closest('[data-action="decrement"]')) return;

  e.preventDefault();

  const component = e.target.closest('[data-component="quantity"]');
  const quantity = component.querySelector('[data-element="quantity-input"]');
  let val = parseInt(quantity.value);
  let min = quantity.getAttribute('min');

  if (min != null && val <= min){ return; }

  quantity.value = --val;
  bubbleChangeEvent(quantity)
});

document.addEventListener('click', e => {
  
  if (!e.target.closest('[data-action="increment"]')) return;

  e.preventDefault();

  const component = e.target.closest('[data-component="quantity"]');
  const quantity = component.querySelector('[data-element="quantity-input"]');
  let val = parseInt(quantity.value);
  let max = quantity.getAttribute('max');

  if (max != null && val >= max){ return; }

  quantity.value = ++val;
  bubbleChangeEvent(quantity)
});



const bubbleChangeEvent = (element) =>{
  const changeEvent = new Event('change', { bubbles: true, cancellable: true })
  element.dispatchEvent(changeEvent)
}



