const searchThumbnail = (item, badge, categoryBadges) =>
  `<a href="${item.url}"
    class="block max-w-[164px] min-w-[164px]"
    data-element="search-items" data-id=${item.id} data-group-id=${
    item.itemGroupId
  }>
    <div class="relative aspect-5/6 w-100 mb-2" data-element="video">
      ${
        item.custom_media_display != null
          ? `<video
          class="custom-media-display object-cover"
          autoPlay="true"
          muted="true"
          loop="true"
          playsInline="true"
          poster="${item.imageUrl}"
          src="${item.custom_media_display.replace('https //', 'https://')}"
          height="218"
          width="164"
        ></video>`
          : `<img
            alt="${item.name}"
            class="object-contain"
            height="218"
            width="164"
            src="${item.imageUrl}"
          />`
      }
      ${
        badge != null &&
        `<div class="mt-2 ${
          badge === 'Sold out' || badge === 'Notify me' ? 'badge-oos' : 'badge'
        }">${badge}</div>`
      }
    </div>
    <div class="flex flex-wrap gap-x-2 gap-y-[2px] md:gap-y-2 md:mb-1">
    ${
      categoryBadges != null &&
      categoryBadges
        .map(
          (category) =>
            `<span class="badge-${category
              ?.toLowerCase()
              .replace(/\s+/g, '-')} capitalize-first">${
              category.includes('Tech') ? category : category.replace('-', ' ')
            }</span>`
        )
        .join(' ')
    }
    </div>
      <h4 class="text-body-small">
        ${item.name}
      </h4>
      <p class="text-caption opacity-60 mb-0">
        ${item.brand}
      </p>

  </a>`

export { searchThumbnail }
