const getSellingPlanData = async () => {
  return fetch(`/cart?view=bump`, {})
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response.json()
    })
    .catch(function (err) {
      console.log('Failed to fetch page: ', err)
    })
}

export { getSellingPlanData }
