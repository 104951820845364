import $ from "cash-dom";

const sectionToggle = $('[data-action="toggle-next-section"]');

const updateBtnTxt = () => {
	$(sectionToggle).text(
		$(sectionToggle).text() == "Shop Bundles" ? "Hide Bundles" : "Shop Bundles"
	);
};

// Hide Next Section by default
if (sectionToggle) {
	$(sectionToggle)
		.closest(".shopify-section")
		.next(".shopify-section")
		.addClass("h-0 overflow-hidden")
		.css("margin-top", "-40px");

	updateBtnTxt();
}

$(sectionToggle).click(function (e) {
	e.preventDefault();

	$(this)
		.closest(".shopify-section")
		.next(".shopify-section")
		.toggleClass("h-0 overflow-hidden");

	updateBtnTxt();
});
