window.dataLayer = window.dataLayer || []

const pushToDataLayer = (event, data) => {
  window.dataLayer.push({ event, data })
  // console.log(window.dataLayer)
}

const buildEcommerceItem = (item) => ({
  'price': item.price / 100,
  'item_id': String(item.id),
  'item_name': item.title,
  'item_brand': item.vendor,
  'item_variant': String(item.variant_id || item.variants[0].id),
  'item_category': item.product_type || item.type,
})

export { pushToDataLayer, buildEcommerceItem }
