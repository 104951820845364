import { getItemKeyInCart } from '../utilities/toolkit'
import { buildEcommerceItem, pushToDataLayer } from '.'

let previousCartState

const dataLayerAddToCart = (data) => {
  const items = data.map((product) => {
    const item = { ...product }
    if (item.selling_plan_allocation) {
      item.title += ' Subscription'
    }
    return {
      ...buildEcommerceItem(item),
      'quantity': item.quantity,
    }
  })

  pushToDataLayer('add_to_cart', {
    'add_to_cart': {
      'ecommerce': {
        'items': items,
        'currency': 'USD',
      },
    },
  })
}

const dataLayerCartEvents = (data) => {
  const previousCart = previousCartState || data
  const itemsAdded = []

  data.items.forEach((item) => {
    const existingItem = getItemKeyInCart(previousCart, item.key)

    if (!existingItem || item.quantity > existingItem.quantity) {
      const quantityDifference = !existingItem
        ? item.quantity
        : item.quantity - existingItem.quantity
      itemsAdded.push({ ...item, quantity: quantityDifference })
    }
  })

  if (itemsAdded.length) dataLayerAddToCart(itemsAdded)
  previousCartState = structuredClone(data)
}

export { dataLayerCartEvents }
