import $ from 'cash-dom'

const scrollToSection = (hash) => {
  if (hash.indexOf('scroll-to') < 0) return

  hash = hash.replace('#scroll-to-', '')
  if ($(hash).length == 0) return

  var pos = $(hash).offset().top - 70
  var delta = Math.abs(pos - window.scrollY)
  var time = 1000 + (delta / window.innerHeight) * 350

  $([document.documentElement, document.body]).animate(
    {
      scrollTop: pos,
    },
    time
  )

  window.location.hash = ''
}

const hashChange = (hash) => {
  if (hash != null && hash.length > 0) {
    scrollToSection(hash)
  }
}

$(document).ready(function () {
  var hash = window.location.hash
  $(window).trigger('hashchange')
  setTimeout(function () {
    hashChange(hash)
  }, 1300)
})

window.addEventListener(
  'hashchange',
  function () {
    var hash = window.location.hash
    if (hash == '#show-account-panel') {
      $('#account-panel').attr('aria-hidden', 'false')
      window.location.hash = ''
      return
    }

    if (hash == '#show-create-account-subpanel') {
      $('[data-action="show-create-account-subpanel"]').click()
      window.location.hash = ''
      return
    }

    if (hash.indexOf('scroll-to-') > -1) {
      scrollToSection(hash)
    }
  },
  false
)

const showSection = () => {
  $('[data-component="sticky-add-to-cart"]').removeClass('hidden')
}
const hideSection = () => {
  $('[data-component="sticky-add-to-cart"]').addClass('hidden')
}

const isInViewport = (el) => {
  const rect = el.getBoundingClientRect()
  return (
    rect.top <= document.documentElement.clientHeight ||
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  )
}

const boxsetSection = document.querySelector('.boxset-section')

if (boxsetSection) {
  document.addEventListener(
    'scroll',
    function () {
      isInViewport(boxsetSection) ? showSection() : hideSection()
    },
    {
      passive: true,
    }
  )
}
