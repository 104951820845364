import $ from 'cash-dom'
import { getCookie, setCookie } from '../utilities/cookies'

const passwordModal = $('#password-modal')
const passwordModalForm = $('#password-modal-form')
const passwordIncorrectMessage = $('#password-modal-pw-incorrect')
const cookieName = 'password-' + location.pathname
const cookieValue = getCookie(cookieName)

const hidePasswordPanel = () => {
  window.scrollTo(0, 0)
  passwordIncorrectMessage.addClass('hidden')
  passwordModal.addClass('opacity-0 blur-md')
  setTimeout(() => {
    passwordModal.attr('aria-hidden', 'true')
    passwordModal.addClass('hidden')
  }, 500)
}

passwordModalForm.on('submit', (e) => {
  e.preventDefault()

  let modal
  if (Pura.template.name == 'product') {
    modal = Pura.product.metafields
  } else if (Pura.template.name == 'collection') {
    modal = Pura.collection.metafields
  } else if (Pura.template.name == 'page') {
    modal = Pura.page.metafields
  }
  const value = $('#password-modal-value').val().trim()?.toLowerCase()
  const password = modal.discount_code?.toLowerCase()

  // Password contains logic
  if (Pura.template.suffix == 'subscriber-day') {
    if (!value.startsWith('sd')) {
      passwordIncorrectMessage.removeClass('hidden')
      return
    }
    // Password not correct
  } else if (password != value) {
    passwordIncorrectMessage.removeClass('hidden')
    return
  }

  // Password correct
  setCookie(cookieName, true, 7)
  hidePasswordPanel()

  return false
})

// Check to see if password alreaady entered
if (cookieValue) {
  hidePasswordPanel()
}
