import $ from 'cash-dom'
import { getCookie, setCookie } from '../utilities/cookies'
import { cart } from './cart'

const checkoutBack = getCookie('clicked_to_checkout')

if (checkoutBack) {
  setCookie('clicked_to_checkout', null, 14)
  cart.update()
}

$('#cart-checkout-button').on('click', () => {
  setCookie('clicked_to_checkout', true, 14)
})
