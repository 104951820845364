import { updateCartAttributes } from '../resources/shopify-cart'

const updateAttributes = (data) => {
  const currentPromoIds = data.attributes?._pura_promo_ids || []

  const promoIds = data.items
    .filter(
      (item) =>
        item.properties?._pura_promo_id &&
        item.properties?._pura_group_role === 'parent'
    )
    .map((item) => item.properties._pura_promo_id)
    .filter(Boolean)

  const updatePromoIds =
    promoIds.length !== currentPromoIds.length ||
    promoIds.some((id, index) => id !== currentPromoIds[index])

  if (updatePromoIds) {
    updateCartAttributes({
      _pura_promo_ids: promoIds,
    })
  }
}

export { updateAttributes }
