import $ from 'cash-dom'

let count = 0
let videos = $('[data-load-video-on-scroll]')

const loadAndPlayVideo = (video) => {
  // Set video URL
  video.find('iframe').attr('src', video.attr('data-load-video-on-scroll'))

  // Load after setting the src
  // if (video[0]) {
  //   setTimeout(function () {
  //     video[0].load()
  //     // Give it a second to fetch the video
  //     setTimeout(function () {
  //       video[0].play()
  //     }, 500)
  //   }, 20)
  // }
}

const loadElementsOnScroll = () => {
  if (videos.length !== 0 && count !== videos.length) {
    for (let i = 0; i < videos.length; ++i) {
      if (videos.eq(i).length) {
        let video = videos[i]
        let position = video.getBoundingClientRect()
        if (
          position.top >= -position.height &&
          position.bottom <=
            (window.innerHeight + 800 ||
              document.documentElement.clientHeight) +
              position.height
        ) {
          count++
          loadAndPlayVideo(videos.eq(i))

          delete videos[i]
        }
      }
    }
  } else {
    document.removeEventListener('scroll', loadElementsOnScroll, {
      passive: false,
    })
  }
}

const loadElementsOnLoad = () => {
  if (videos.length !== 0 && count !== videos.length) {
    count++

    loadAndPlayVideo(videos.eq(0))
    loadAndPlayVideo(videos.eq(1))

    delete videos[0]
    delete videos[1]
  }
}

document.addEventListener('scroll', loadElementsOnScroll, { passive: false })
window.addEventListener('load', loadElementsOnLoad)
