import $ from 'cash-dom'
import {
  loadImages,
  setSelectionPanelToCar,
  setSelectionPanelToHome,
  setSelectionPanelToLegacy,
  setSelectionPanelToSmartvial,
  updateBYSPanelPrice,
} from './bys-utils'

const selectionPanel = $('#BYGS-selection-panel')
const selectionSlider = selectionPanel.find('#BYS-selection-slider')
const priceEle = selectionPanel.find('[data-content="BYS-selected-price"]')
const comparePriceEle = selectionPanel.find(
  '[data-content="BYS-selected-compare-price"]'
)

const carBadge = selectionPanel.find('.badge-car')
const homeBadge = selectionPanel.find('.badge-home')

const fragranceDisplay = $('[data-content="BYS-selected-fragrances"]')
const fragrancesCol1 = $('[data-content="BYS-fragrances-col-1"]')
const fragrancesCol2 = $('[data-content="BYS-fragrances-col-2"]')

let productData

const showSelectionPanel = (_productData) => {
  productData = _productData

  const { title, description, fragrances, available, sub_variant_id, images } =
    productData

  if (Pura.BYGS.device_type == 'Car') {
    setSelectionPanelToCar(homeBadge, carBadge)
  } else {
    setSelectionPanelToHome(homeBadge, carBadge)
  }
  if (Pura.BYGS.device_type == 'Pura 4') {
    setSelectionPanelToSmartvial(selectionPanel)
  } else {
    setSelectionPanelToLegacy(selectionPanel)
  }

  updateBYSPanelPrice(productData, comparePriceEle, priceEle)

  selectionPanel.find('[data-content="BYS-selected-title"]').html(title)

  selectionPanel
    .find('[data-content="BYS-selected-description"]')
    .html(description)

  let data = fragrances?.trim().split('<br />') || false

  if (data) {
    data = data.filter((item) => item)
    let htmlCol1 = ''
    for (let i = 0; i < 3; ++i) {
      htmlCol1 += `<li>${data[i].replace('<br>', '')}</li>`
    }

    let htmlCol2 = ''
    for (let k = 3; k < 6; ++k) {
      if (data[k]) {
        htmlCol2 += `<li>${data[k].replace('<br>', '')}</li>`
      }
    }

    fragranceDisplay.show()
    fragrancesCol1.html(htmlCol1)
    fragrancesCol2.html(htmlCol2)
  } else {
    fragranceDisplay.hide()
  }

  if (available) {
    selectionPanel
      .find('[data-action="add-selected-product-to-BYS"]')
      .removeAttr('disabled')
    selectionPanel
      .find('[data-action="add-selected-product-to-BYS"]')
      .attr('data-variant-id', sub_variant_id)
  } else {
    selectionPanel
      .find('[data-action="add-selected-product-to-BYS"]')
      .prop('disabled', true)
    selectionPanel
      .find('[data-action="add-selected-product-to-BYS"]')
      .attr('data-variant-id', '')
  }

  loadImages(images, selectionSlider, selectionPanel, title)
}

const hideSelectionPanel = () => {
  selectionPanel[0].hide()
}

selectionPanel.find('[name="purchase-type"]').on('change', (e) => {
  updateBYSPanelPrice(productData, comparePriceEle, priceEle)
})

export { showSelectionPanel, hideSelectionPanel }
