


function jsonToQueryString(json, parentKey = '') {
  let queryString = [];

  for (let key in json) {
    if (json.hasOwnProperty(key)) {
      let fullKey = parentKey ? `${parentKey}[${key}]` : key;
      let value = json[key];

      // Check if value is an object, if so, recursively call the function
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        queryString.push(jsonToQueryString(value, fullKey));
      } else {
        // Convert value to a string and encode it
        queryString.push(encodeURIComponent(fullKey) + '=' + encodeURIComponent(value));
      }
    }
  }

  return queryString.join('&');
}

const getCheckoutAddressParams = (cartObj) => {


	const jsonObject = {
		checkout: {
			shipping_address: {
		    first_name: cartObj.attributes.recipient_first_name || "",
		    last_name: cartObj.attributes.recipient_last_name || "",
		    address1: cartObj.attributes.street_address_1 || "",
		    address2: cartObj.attributes.street_address_2 || "",
		    city: cartObj.attributes.city || "",
		    province: cartObj.attributes.state || "",
		    zip: cartObj.attributes.zip || ""
			}
		}
	};

	return jsonToQueryString(jsonObject)
}


export { getCheckoutAddressParams }
