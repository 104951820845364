import $ from 'cash-dom'
import { getCheckoutAddressParams } from './cart-checkout-BYGS'
import { getCart } from '../resources/shopify-cart'

const checkoutButton = $('[data-action="checkout"]')
const agreeToSubscriptionTerms = $(
  '[data-action="agree-to-subscription-terms"]'
)
let cartToken = ''
let discountCode, discountCodeRule

checkoutButton.on('click', (e) => {
  e.preventDefault()

  getCart()
    .then((cart) => {
      cartToken = cart.token

      let addressParams = getCheckoutAddressParams(cart)

      // If we have locked items show terms panel instead
      if (hasLockedItem(cart)) {
        let modalTermsPanel = document.getElementById(
          'free-diffuser-terms-panel'
        )
        if (modalTermsPanel) {
          modalTermsPanel.show()
        }
        return
      }

      // check for auto apply discount code
      let discountCodeItem = getAutoApplyDiscountCodeItem(cart.items)
      if (discountCodeItem) {
        discountCode =
          discountCodeItem.properties._pura_auto_apply_discount.toUpperCase()
      }

      if (discountCode) {
        window.location = `/checkout?discount=${discountCode}`
        return
      }

      window.location = `/checkout?${addressParams}`
    })
    .catch((error) => {
      console.log('error getting cart for recharge', error)
    })
})

agreeToSubscriptionTerms.on('click', (e) => {
  e.preventDefault()
  let url = '/checkout'
  if (discountCode) {
    url += `?discount=${discountCode}`
  }
  window.location = url
})

const hasSubscriptionItem = (cart) => {
  let item,
    items = cart.items
  for (let i = 0; i < items.length; ++i) {
    item = items[i]
    if (item.selling_plan != null) {
      return true
    }
  }
  return false
}

const hasLockedItem = (cart) => {
  let item,
    items = cart.items
  for (let i = 0; i < items.length; ++i) {
    item = items[i]
    if (item.properties != null && item.properties._pura_lock_duration > 0) {
      return true
    }
  }
  return false
}

const getAutoApplyDiscountCodeItem = (items) => {
  let item
  for (let i = 0; i < items.length; ++i) {
    item = items[i]
    if (
      item.properties != null &&
      item.properties._pura_auto_apply_discount != null
    ) {
      return item
    }
  }
  return false
}

export { hasSubscriptionItem }
