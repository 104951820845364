window.addEventListener('load', () => {
  hideVideo()
})

const hideVideo = () => {
  const videoElements = document.querySelectorAll('.custom-media-display')

  videoElements.forEach((videoElement) => {
    let promise = videoElement.play()

    if (promise !== undefined) {
      promise
        .catch((error) => {
          if (error.name === 'NotAllowedError') {
            videoElement.remove()
            document
              .querySelectorAll('.product-thumbnail-main-image.hidden')
              .forEach((el) => {
                el.classList.remove('hidden')
              })
          }
        })
        .then(() => {
          videoElement.play()
        })
    }
  })
}
