import $ from 'cash-dom'

const buildOptions = (customer, option) => {
  let pointsBalance = customer.pointsBalance
  let costInPoints = option.costInPoints

  if (pointsBalance >= costInPoints) {
    return `<option class="redemption-option" value="${option.id} + ${costInPoints} + ${option.discountType}" data-id="${costInPoints}">${option.name} for ${option.costText}</option>`
  }
}

const buildRewardOptions = (customer, rewards) => {
  const existingSubRewards = rewards.filter(
    (reward) =>
      reward.appliesToId !== null &&
      reward.discountType === 'recharge_discount_fixed_amount'
  )

  existingSubRewards.forEach((option) => {
    $('select#redemption-options').append(buildOptions(customer, option))
  })
}
export { buildRewardOptions }
