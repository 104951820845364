import { buildEcommerceItem, pushToDataLayer } from '.'

const dataLayerViewEvent = () => {
  const item = buildEcommerceItem(Pura.product)

  pushToDataLayer('view_item', {
    'view_item': {
      'ecommerce': {
        'items': [item],
        'currency': 'USD',
      },
    },
  })
}

if (Pura.template.name === 'product') dataLayerViewEvent()
