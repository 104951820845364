import $ from 'cash-dom'
import {
  getCart,
  addItemsToCart,
  updateCartAttributes,
} from '../resources/shopify-cart'
import { generateUID } from '../utilities/toolkit'
import { cart } from './cart'
import serialize from 'form-serialize'
import { showSelectionPanel, hideSelectionPanel } from './BYGS-selection-panel'
import { getNewPackingId } from '../utilities/cart-packing-id'

class BuildYourGiftingSet {
  constructor(maxProducts) {
    const _ = this

    _.headerButtonsPanel = $('#BYS-panel-1 .panel-buttons')
    _.instructionsPanel = $('#bygs-instructions')
    _.productsPanel = $('#bygs-products')
    _.subtotalElements = $('[data-content="bygs-subtotal"]')
    _.addressPanel = $('#bygs-address-panel')
    _.confirmAddressModal = document.getElementById('confirm-address-panel')
    _.addToCartButton = $('#add-BYGS-to-cart-button')

    // currentStep indexs are 1 and 2,
    // they don't start at 0 to keep naming consistent
    _.currentStep = 1

    // selected products array
    // we only use indexes 1 and 2
    _.selectedProducts = []

    // for product quick view panel
    _.selectedProduct = {}

    _.bindUI()
  }

  bindUI() {
    const _ = this

    // Show details panel
    document.addEventListener('click', (e) => {
      if (!e.target.closest('[data-action="show-BYS-selection-panel"]')) return

      // get product data
      const productThumbnail = e.target.closest(
        '[data-action="show-BYS-selection-panel"]'
      )
      const productId = productThumbnail.getAttribute('data-product-id')
      _.selectedProduct = JSON.parse($(`#product-data-${productId}`).html())

      // show panel, pass in data
      showSelectionPanel(_.selectedProduct, _.currentStep)
    })

    // Add product to boxset
    $('[data-action="add-selected-product-to-BYS"]').on('click', (e) => {
      e.preventDefault()

      let selectedProducts = _.selectedProducts

      // get max products
      let maxProducts = 2
      if (Pura.BYGS.device_type == 'Car') {
        maxProducts = 1
      }

      // remove last item if already at max
      if (selectedProducts.length == maxProducts) {
        selectedProducts.pop()
      }

      // check for OTP or SUB purchase type
      let purchaseType = $(
        '#BYS-selection-panel [name="purchase-type"]:checked'
      ).val()

      if (purchaseType != null) {
        // save the purchase type
        _.selectedProduct.purchaseType = purchaseType

        if (purchaseType == 'SUB') {
          // add sub frequency
          _.selectedProduct.subFrequency = $(
            '#BYS-selection-panel #sub-interval-frequency'
          ).val()
        }
      }

      // pushing product to selected products array
      selectedProducts.push(_.selectedProduct)

      // update header content
      _.updateHeaderContent()
      // hide panel
      hideSelectionPanel()
      // scroll to top of page
      window.scrollTo(0, 0)
    })

    // Remove product from boxset
    $('[data-action="remove-product-from-BYS"]').on('click', (e) => {
      e.preventDefault()

      let productsList = _.selectedProducts
      // get closest component
      let component = e.target.closest(
        '[data-component="BYS-selected-product"]'
      )

      // remove product from the array
      if (component.getAttribute('data-index') == '0') {
        productsList.splice(0, 1)
      } else {
        productsList.splice(1, 1)
      }
      _.updateHeaderContent()
      _.gotoStep1()
    })

    $('[data-action="BYGS-goto-step-1"]').on('click', (e) => {
      _.gotoStep1()
    })

    $('[data-action="BYGS-goto-step-2"]').on('click', (e) => {
      _.gotoStep2()
    })

    $('#gift-a-sub-atc').on('submit', function (e) {
      e.preventDefault()
      const formData = serialize(this, { hash: true })
      // show address confirmation modal
      _.showAddressConfirmModal(formData)
    })

    // add to cart button on address confirmation modal
    $('[data-action="confirm-address-atc"]').click(function (e) {
      e.preventDefault()
      _.hideAddressConfirmModal()
      _.addAddressDataToCart()
      getCart().then((cartData) => {
        _.addToCart(cartData)
      })
    })

    $('#include-gift-bag').change((e) => {
      _.updatePanelContent()
    })

    $('#bygs-address-panel input').change((e) => {
      _.addressFormValid()
    })
  } // END bind UI

  updateHeaderContent() {
    this.updatePanelContent()
  }

  // index are 1 and 2,
  // they don't start at 0 to keep naming consistent
  updatePanelContent() {
    const _ = this
    let products = _.selectedProducts
    let panel = $(`#BYS-panel-1`)
    let components = panel.find('[data-component="BYS-selected-product"]')
    let selectHeader = panel.find('.select-header')
    let sitewide_discount = Pura.cart.enable_sitewide_discount
    let subtotal = Pura.product.price

    if (sitewide_discount) {
      subtotal = subtotal * (1 - Pura.cart.discount_amount / 100)
    }

    // hide or show "Select your fragrances" header
    if (products.length == 0) {
      selectHeader.show()
    } else {
      selectHeader.hide()
    }

    // reset all components
    components.removeClass('is-active')
    components.find('[data-content="fragrance-name"]').html('')
    components.find('.free-label').removeClass('hidden')

    // set the data for the selected product
    products.forEach((product, i) => {
      let element = components.eq(i)
      let price = product.price

      element.addClass('is-active')
      element.find('img').attr('src', product.images[0])
      let titleElement = element.find('[data-content="fragrance-name"]')
      titleElement.html(product.title)

      element.find('.free-label').addClass('hidden')

      if (sitewide_discount) {
        price = product.price * (1 - Pura.cart.discount_amount / 100)
      }

      subtotal += price
    })

    // add gift bag price to subtotal?
    let includeGiftBag = document.getElementById('include-gift-bag')
    if (includeGiftBag.checked) {
      subtotal += 399
    }

    // update total price
    _.subtotalElements.html(`$${(subtotal / 100).toFixed(2)}`)

    // should we show continue / ATC button ?

    // get max products
    let maxProducts = 2
    if (Pura.BYGS.device_type == 'Car') {
      maxProducts = 1
    }

    let button = panel.find('.panel-buttons button')
    if (products.length == maxProducts) {
      button.removeAttr('disabled')
    } else {
      button.prop('disabled', true)
    }
  }

  addressFormValid() {
    const _ = this
    let senderEmail = document.getElementById('sender-email').value
    let addy1 = document.getElementById('recipient-address-1').value
    let zip = document.getElementById('recipient-address-zip').value

    // enable button
    if (senderEmail && addy1 && zip) {
      _.addToCartButton.removeAttr('disabled')
      return
    }

    _.addToCartButton.prop('disabled', true)
  }

  gotoStep1() {
    const _ = this

    _.headerButtonsPanel.removeClass('hidden')
    _.addressPanel.addClass('hidden')
    _.instructionsPanel.removeClass('hidden')
    _.productsPanel.removeClass('hidden')
    _.addressFormValid()
    window.scrollTo(0, 0)
  }

  gotoStep2() {
    const _ = this

    _.headerButtonsPanel.addClass('hidden')
    _.instructionsPanel.addClass('hidden')
    _.productsPanel.addClass('hidden')
    _.addressPanel.removeClass('hidden')
    _.addressFormValid()
    window.scrollTo(0, 0)
  }

  addAddressDataToCart() {
    const form = document.getElementById('gift-a-sub-atc')
    // get form data
    const formData = serialize(form, { hash: true })
    let cartAttributes = { ...formData }
    cartAttributes.recipient_email = cartAttributes.recipient_email || null
    cartAttributes.street_address_2 = cartAttributes.street_address_2 || null
    // remove this field from the attributes request
    delete cartAttributes.include_gift_bag
    // add attributes to the cart
    updateCartAttributes(cartAttributes)
  }

  showAddressConfirmModal(formData) {
    const _ = this
    const panel = $(_.confirmAddressModal)

    panel
      .find('[data-content="address-line-1"]')
      .html(formData.street_address_1 || '')
    panel
      .find('[data-content="address-line-2"]')
      .html(formData.street_address_2 || '')
    panel.find('[data-content="address-city"]').html(`${formData.city || ''},`)
    panel.find('[data-content="address-state"]').html(formData.state || '')
    panel.find('[data-content="address-zip"]').html(formData.zip || '')

    _.confirmAddressModal.show()
  }

  hideAddressConfirmModal() {
    const _ = this
    _.confirmAddressModal.hide()
  }

  addToCart(cartData) {
    const _ = this
    let groupID = generateUID()
    let displayIndex = 0
    let gifBagElement = document.getElementById('include-gift-bag')

    let gwpProduct =
      Pura.productForm.gwp.hasGWPProduct === 'true'
        ? Pura.productForm.gwp.variantID
        : 'false'

    let currentDate = new Date().getTime()
    let gwpStartTime = Pura.productForm.gwp.startTime * 1000
    let gwpEndTime = Pura.productForm.gwp.endTime * 1000

    if (currentDate < gwpStartTime || currentDate > gwpEndTime) {
      gwpProduct = 'false'
    }
    // include gift bag?
    let includeGiftBag = false
    if (gifBagElement.checked) {
      includeGiftBag = true
    }

    // get new packing Id with most recent cart
    let packingId = getNewPackingId(cartData)

    // calculate sub duration
    let subDuration = '3 mo. subscription'
    if (Pura.BYGS.collection.indexOf('6-month') > -1) {
      subDuration = '6 mo. subscription'
    }

    // is this a home or car device
    let step1CartTag = 'Home'
    let headingText = `Pura 4 set: ${subDuration}`
    if (Pura.BYGS.device_type == 'Car') {
      step1CartTag = 'Car'
      headingText = `Car set: ${subDuration}`
    }

    // Build the request object and add the step 1 device
    let request = {
      items: [
        {
          id: Pura.product.variants[0].id,
          quantity: 1,
          properties: {
            _pura_group_id: groupID,
            _pura_group_role: 'parent',
            _pura_display_order: displayIndex++,
            _pura_cart_heading: headingText,
            _pura_cart_heading_tag: step1CartTag,
            _pura_packing_id: packingId,
            _pura_full_price: Pura.product.variants[0].price / 100,
            _pura_full_price_currency: 'USD',
          },
        },
      ],
    }

    // Add step items to request
    let newItem, intervalFrequency
    let selectedProducts = _.selectedProducts
    let product

    for (let i = 0, n = selectedProducts.length; i < n; ++i) {
      product = selectedProducts[i]

      newItem = {
        id: product.variant_id,
        quantity: 1,
        selling_plan: product.selling_plans[0].id,
        properties: {
          _pura_group_id: groupID,
          _pura_group_role: 'child',
          _pura_UID: generateUID(),
          _pura_display_order: displayIndex++,
          _pura_packing_id: packingId,
          _pura_full_price: product.price,
          _pura_full_price_currency: 'USD',
        },
      }

      // push item to request array
      request.items.push(newItem)
    }

    const giftBagVariant = Pura.environment.production
      ? 40743150125165
      : 42304984809572

    // add gift bag
    if (includeGiftBag) {
      newItem = {
        id: giftBagVariant,
        quantity: 1,
        properties: {
          _pura_group_id: groupID,
          _pura_group_role: 'child',
          _pura_UID: generateUID(),
          _pura_display_order: displayIndex++,
          _pura_cart_message: 'Ships one time',
          _pura_packing_id: packingId,
          _pura_full_price: Pura.cart.gift_bag_price / 100,
          _pura_full_price_currency: 'USD',
        },
      }
      // push item to request array
      request.items.push(newItem)
    }

    if (gwpProduct !== 'false') {
      request.items.push({
        id: gwpProduct,
        quantity: 1,
        properties: {
          _pura_group_id: groupID,
          _pura_group_role: 'child',
          _pura_UID: generateUID(),
          _pura_display_order: displayIndex++,
        },
      })
    }
    // send request to add items
    addItemsToCart(request)
      .then((res) => {
        // reset data and render
        _.selectedProducts = []
        _.gotoStep1()
        _.updateHeaderContent()
        cart.updateAndShow()
      })
      .catch((err) => {
        console.log('error adding boxset', err)
      })
  } // END addToCart
} // END BYS

if (Pura.template.suffix == 'gift-a-subscription') {
  const BYGS = new BuildYourGiftingSet()
}
