import $ from 'cash-dom'

function changeTab(target) {
  let currentTab = document.querySelector('.tabs a.active')
  if (currentTab) {
    currentTab.classList.remove('active')
  }

  $(target).addClass('active')

  let panelId = $(target).attr('href').split('#')[1]
  let currentPanel = document.querySelector('.tab-content:not(.hidden)')
  if (currentPanel) {
    currentPanel.classList.add('hidden')
  }

  let panel = document.getElementById(panelId)
  if (panel) {
    panel.classList.remove('hidden')
  }

  let currentHeadline = document.querySelector('h1 span:not(.hidden)')
  if (currentHeadline) {
    currentHeadline.classList.add('hidden')
  }

  let headline = document.querySelector('.' + panelId + '_headline')
  if (headline) {
    headline.classList.remove('hidden')
  }

  const newUrl =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname +
    `?type=${panelId}`
  window.history.pushState({ path: newUrl }, '', newUrl)
}

function activateTabFromUrl() {
  const urlParams = new URLSearchParams(window.location.search)
  const typeParam = urlParams.get('type')

  if (typeParam) {
    let matchingTab = document.querySelector(`.tabs a[href="#${typeParam}"]`)
    if (matchingTab) {
      changeTab(matchingTab)
    }
  }
}

let links = $('.tabs a')

links.on('click', (e) => {
  e.preventDefault()
  changeTab(e.target)
})

activateTabFromUrl()
