import $ from 'cash-dom'
import { buildReviews } from './yotpo-ratings'
import {
  getCollection,
  getPersonalizations,
  getProductRecommendations,
} from '../resources/klevu-api'
import { addToCartButton } from './product-form'
import { productGrid } from '../templates/klevu-product-grids'
import { getKlevuBadge, getKlevuCategoryBadge } from './klevu-badges'
import { getProductPricing } from './sitewide-discount'
import { buildLazyLoad } from './video'

// // Klevu Collection
$('.klevu-collection-section-slider').each(function () {
  let collectionID = $(this).attr('data-id').replace(/-/g, ' ')

  getCollection(collectionID).then((products) => {
    products = products.queriesById('collection')?.records

    let slider = $(this)
    slider.slick('removeSlide')

    buildSlider(products, slider, collectionID, null, null)
    buildReviews()
    addToCartButton()
    buildLazyLoad()
  })
})

// Klevu Personalized Recommendations
let personalizationClickManager
let personalizedRecs = 'personalizedRecs'

if ($('#personalized-section-slider').length > 0) {
  getPersonalizations(personalizedRecs).then((products) => {
    const result = products.queriesById(personalizedRecs)
    products = products.queriesById(personalizedRecs).records
    personalizationClickManager = result.getRecommendationClickSendEvent()

    let slider = $('#personalized-section-slider')
    slider.slick('removeSlide')

    buildSlider(products, slider, null, personalizedRecs, null)
    buildReviews()
    addToCartButton()
    buildLazyLoad()

    // Send Analytic Events to Klevu
    $('[data-element="personalized-products"]').each(function () {
      $(this).on('click', function () {
        const productId = this.getAttribute('data-id')
        const productGroupId = this.getAttribute('data-group-id')

        personalizationClickManager(productId, productGroupId)
      })
    })
  })
}

// Klevu Product Recommendations
let recommendationClickManager
let productRecs = 'productRecs'

if ($('#recommendation-section-slider').length > 0) {
  getProductRecommendations(productRecs).then((products) => {
    const result = products.queriesById(productRecs)
    products = products.queriesById(productRecs).records
    recommendationClickManager = result.getRecommendationClickSendEvent()
    let slider = $('#recommendation-section-slider')

    if (products.length === 0) {
      $('#product-recommendations').addClass('hidden')
      return
    } else {
      slider.slick('removeSlide')
      buildSlider(products, slider, null, null, productRecs)
      buildReviews()
      addToCartButton()
      buildLazyLoad()
    }

    // Send Analytic Events to Klevu
    $('[data-element="recommended-products"]').each(function () {
      $(this).on('click', function () {
        const productId = this.getAttribute('data-id')
        const productGroupId = this.getAttribute('data-group-id')

        recommendationClickManager(productId, productGroupId)
      })
    })
  })
}

//Build Slider
const buildSlider = (
  products,
  slider,
  collectionID,
  personalizedRecs,
  productRecs
) => {
  let atcButton = null
  let badge
  let categoryBadges = []
  let reviews = null
  let comparePrice = null

  if ($(slider).hasClass('add-to-cart-button')) {
    atcButton = 'isActive'
  }

  if ($(slider).hasClass('enable_reviews')) {
    reviews = 'isActive'
  }

  // only take the first 12 items
  if (products.length > 12) {
    products = products.slice(0, 12)
  }

  products.forEach((item) => {
    // start with regular price
    let price = parseFloat(item.price)
    let productType = item.product_type
    let showPrice = true
    let showReviews = true

    if (item.tags.includes('hide_Reviews')) {
      showReviews = false
    }

    if (item.tags.includes('hide_Price')) {
      showPrice = false
    }

    // if it is a bundle use that price
    if (item.bundle_price) {
      price = item.bundle_price.replace('$', '').replace('+', '')
      price = parseFloat(price)
    }

    // if it has a subscription discount then apply that
    if (productType === 'Fragrance' || productType === 'Car Fragrance') {
      price *= (100 - 20) / 100
    }

    let originalPrice = parseFloat(price.toFixed(2))
    price = getProductPricing(price, productType)

    if (originalPrice > parseFloat(price)) {
      comparePrice = parseFloat(item.price)
      if (comparePrice < originalPrice) {
        comparePrice = item.bundle_full_price || item.bundle_price
        comparePrice = comparePrice?.replace('$', '').replace('+', '') || null
      }
    }

    // add plus sign back in if it originally existed
    if (item.bundle_price && item.bundle_price.indexOf('+') > 0) {
      price = `${price}+`
    }

    badge = getKlevuBadge(item)
    categoryBadges = getKlevuCategoryBadge(item)
    slider.slick(
      'slickAdd',
      `<div>
				<div>
					${productGrid(
            item,
            price,
            comparePrice,
            badge,
            categoryBadges,
            collectionID,
            personalizedRecs,
            productRecs,
            atcButton,
            reviews,
            showPrice,
            showReviews
          )}
				</div>
			</div>`
    )
  })
}
