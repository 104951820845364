import $ from 'cash-dom'
import { addToCartButton } from './product-form'
import { getURLParam } from '../utilities/toolkit'
import { handleSwatchClick } from './product-options'
import { disableVideoFullScreen } from './video'
import { reInitSliders } from './sliders'
import { updateSectionImages } from './image-color-changes'

if (Pura.template.suffix === 'bys-landing-page') {
  const observeStickyBar = ($stickyBar, $trackedElement) => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $stickyBar.removeClass('pb-[env(safe-area-inset-bottom)]')
        } else {
          $stickyBar.addClass('pb-[env(safe-area-inset-bottom)]')
        }
      })
    }
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      threshold: 0,
    })
    observer.observe($trackedElement[0])
    return observer
  }
  const initStickyObserver = () => {
    const $stickyBar = $('[data-element="bys-sticky"]')
    const $trackedElement = $('[data-element="bys-add-to-cart"]')

    if ($stickyBar.length && $trackedElement.length) {
      return observeStickyBar($stickyBar, $trackedElement)
    }
    return null
  }
  $(document).ready(() => {
    let observer = initStickyObserver()
    const $productItems = $('[data-component="product-item"]')
    const $productList = $('[data-component="product-list"]')
    const $learnMoreBtn = $('[data-element="learn-more"]')

    const defaultSelected = getURLParam('device')
    if (defaultSelected) {
      const $matchingItem = defaultSelected
        ? $(`[data-component="product-item"][data-handle="${defaultSelected}"]`)
        : null
      if ($matchingItem) {
        $matchingItem.prependTo($productList)
        setTimeout(() => {
          $matchingItem.trigger('click')
          $productList.removeClass('invisible')
        }, 50)
      }
    }

    const $modal = $('#learn-more-modal')
    const $modalData = $('[data-element="modal-data"]')
    const $giftCheckbox = $('#gift-checkbox')
    const $colorSwatches = $(
      'fieldset[data-component="color-swatches"] input[type="radio"]'
    )

    function expandAccordion($element) {
      if ($element.length && $element[0]) {
        $element.css({
          'max-height': `${$element[0].scrollHeight}px`,
          'opacity': 1,
          'transition': 'max-height 0.5s ease, opacity 0.5s ease',
        })
        requestAnimationFrame(() => {
          $element.addClass('expanded')
        })
      }
    }

    function collapseAccordion($element) {
      if ($element.length) {
        $element.css({
          'max-height': 0,
          'opacity': 0,
          'transition': 'max-height 0.5s ease, opacity 0.5s ease',
        })
        requestAnimationFrame(() => {
          $element.removeClass('expanded')
        })
      }
    }

    const findProductDataByVariant = (variantId) =>
      Pura.BYSTemplate.products.bys_products.find((product) =>
        product.variants.some((variant) => variant.id === variantId)
      )

    const rebuildSwiper = (productMedia) => {
      const $swiperGallery = $(
        '[data-component="swiper-gallery"] swiper-container'
      )
      let navigationNextEl = ''
      let navigationPrevEl = ''

      if ($swiperGallery.length) {
        navigationNextEl = $swiperGallery.attr('navigation-next-el')
        navigationPrevEl = $swiperGallery.attr('navigation-prev-el')

        $swiperGallery.remove()
      }

      const swiperContainer = `
        <swiper-container class="h-full" cssMode="true" grid-rows="1" mousewheel-force-to-axis="true" loop="true"
        navigation="true" slides-per-view="1" speed="800" init="false" data-element="updated-slider"
        navigation-next-el="${navigationNextEl}" navigation-prev-el="${navigationPrevEl}">
        </swiper-container>`

      $('[data-component="swiper-gallery"]').html(swiperContainer)

      productMedia.forEach((media) => {
        let slide
        if (media.media_type == 'video') {
          slide = `
          <swiper-slide class="h-full">
              <div class="w-100 mx-auto video-background relative" data-element="video">
               <video playsinline="playsinline" autoplay="autoplay" loop="loop" muted="muted" preload="metadata" class="lazy-video fade-in-media" poster="${media.preview_image}" data-src="${media.url}" aria-label="${media.alt}">
                <source data-src="${media.url}" type="video/mp4">
              </video>
              </div>

          </swiper-slide>`
        } else {
          slide = `
          <swiper-slide class="h-full">
              <div class="w-100 mx-auto video-background" data-element="image">
                <img src="${media.src}" alt="${media.alt}" width="${media.width}" height="${media.height}" loading="lazy" class="object-cover object-center absolute-fill fade-in-media">
              </div>
          </swiper-slide>`
        }

        $('[data-element="updated-slider"]').append(slide)
      })

      const checkSwiperReady = setInterval(() => {
        const newSwiper = document.querySelector(
          '[data-element="updated-slider"]'
        )
        if (newSwiper && typeof newSwiper.initialize === 'function') {
          clearInterval(checkSwiperReady)
          newSwiper.initialize()

          const $videoElements = $(newSwiper).find(
            '[data-element="video"] video'
          )
          const $imageElements = $(newSwiper).find('[data-element="image"] img')

          if (window.Pura.isInApp) {
            disableVideoFullScreen()
          } else {
            $imageElements.addClass('loaded')
            $videoElements.each((index, video) => {
              const $video = $(video)
              const videoSrc = $video.attr('data-src')
              const $source = $video.find('source')
              const sourceSrc = $source.attr('data-src')

              if (videoSrc && sourceSrc) {
                $video.attr('src', videoSrc)
                $source.attr('src', sourceSrc)

                $video.addClass('loaded')
                video.load()
              }
            })
          }
        }
      }, 50)
    }

    const expandContent = ($target) => {
      const $additionalInfo = $target.find('[data-element="additional-info"]')
      const isExpanded = $additionalInfo.hasClass('expanded')

      $productItems.each(() => {
        const $hideProductItems = $productItems.find(
          '[data-element="additional-info"]'
        )
        collapseAccordion($hideProductItems)
      })

      if (!isExpanded) {
        expandAccordion($additionalInfo)
      }
    }

    const updateButtons = (variantId, product) => {
      const isChecked = $giftCheckbox.is(':checked')
      if (!product) return

      const continueUrl = isChecked
        ? product['subscription-button-url']
        : product['button-url']
      const continueText = isChecked
        ? product['subscription-button-text']
        : product['button-text']

      const cartButtonContent = isChecked
        ? `<a class="button-outline w-full" data-element="bys-add-to-cart" href="${
            product['one-time-button-url']
          }"  ${product.available === 'false' && 'disabled'}>${
            product['one-time-button-text']
          }</a>`
        : `<button class="button-outline w-full" data-element="bys-add-to-cart" data-action="add-variant-to-cart" data-variant="${variantId}"
        ${product.available === 'false' && 'disabled'}>
         Add to cart
        </button>`

      if (variantId == 44238775287908 || variantId == 41779671400557) {
        $('[data-element="bys-continue"]')
          .attr('href', product['button-2-url'])
          .text(product['button-2-text'])
      } else {
        $('[data-element="bys-continue"]')
          .attr('href', continueUrl)
          .text(continueText)
      }

      if (product.available == 'false') {
        $('[data-element="bys-continue"]').attr('disabled', true)
        $('[data-element="bys-additional-cta"]').attr('disabled', true)
      } else {
        $('[data-element="bys-continue"]').removeAttr('disabled')
        $('[data-element="bys-additional-cta"]').removeAttr('disabled')
      }

      $('[data-element="bys-add-to-cart"]').replaceWith(cartButtonContent)

      if (!isChecked) addToCartButton()
    }

    const handleAutoSelectGift = () => {
      const isGiftAutoSelected = $giftCheckbox.prop('checked')
      if (isGiftAutoSelected) {
        $giftCheckbox.closest('.input-group-checkbox').hide()
        const selectedProductItem = $productItems.filter('.border-default')
        const selectedSwatch = selectedProductItem.find(
          '[data-element="product-options"] input.swatch-input:checked'
        )
        const selectedVariantId = selectedSwatch.length
          ? Number(selectedSwatch.val())
          : selectedProductItem.data('variant-id')
        const product = findProductDataByVariant(selectedVariantId)
        updateButtons(selectedVariantId, product)
      }
    }

    handleAutoSelectGift()
    const reObserveStickyBar = () => {
      if (observer) observer.disconnect()
      observer = initStickyObserver()
    }

    $colorSwatches.on('change', (e) => {
      const currentSwatch = $(e.currentTarget)
      const selectedVariantId = Number(currentSwatch.data('value'))
      const product = findProductDataByVariant(selectedVariantId)
      const productCard = currentSwatch.closest(
        '[data-component="product-item"]'
      )
      productCard.attr('data-variant-id', selectedVariantId)

      const mobileImage = productCard.find('[data-element="mobile-image"] img')
      let productMedia = product.media

      const isPuraPlus =
        selectedVariantId == 44238775255140 ||
        selectedVariantId == 44238775287908 ||
        selectedVariantId == 41779671367789 ||
        selectedVariantId == 41779671400557

      if (isPuraPlus) {
        mobileImage.each((index, media) => {
          $(media).toggleClass('hidden')
        })

        productMedia = product.media.filter(
          (item) => item && item.alt.includes(currentSwatch.val())
        )
      }

      updateButtons(selectedVariantId, product)
      rebuildSwiper(productMedia)
      reObserveStickyBar()
    })

    $productItems.on('click', (e) => {
      const $target = $(e.currentTarget)
      if (
        $(e.target).closest(
          '[data-element="learn-more"], [data-element="product-options"]'
        ).length
      ) {
        return
      }
      const $radioOptions = $target.find('input[type="radio"][name="color"]')
      const selectedSwatch = $radioOptions.filter(':checked')

      let swatchValue = null
      if ($radioOptions) {
        swatchValue = selectedSwatch.val()
      }

      const selectedVariantId = selectedSwatch.length
        ? Number(selectedSwatch.data('value'))
        : $target.data('variant-id')

      const product = findProductDataByVariant(selectedVariantId)
      let productMedia = product.media

      const isPuraPlus =
        selectedVariantId == 44238775255140 ||
        selectedVariantId == 44238775287908 ||
        selectedVariantId == 41779671367789 ||
        selectedVariantId == 41779671400557

      if (isPuraPlus) {
        productMedia = product.media.filter(
          (item) => item && item.alt.includes(swatchValue)
        )
      }
      $productItems.removeClass('border-default').addClass('border-white')
      $target.removeClass('border-white').addClass('border-default')

      updateButtons(selectedVariantId, product)
      rebuildSwiper(productMedia)
      expandContent($target)
      reObserveStickyBar()
    })

    $giftCheckbox.on('change', () => {
      const selectedProductItem = $productItems.filter('.border-default')
      const selectedSwatch = selectedProductItem.find(
        '[data-element="product-options"] input.swatch-input:checked'
      )
      const selectedVariantId = selectedSwatch.length
        ? Number(selectedSwatch.val())
        : selectedProductItem.data('variant-id')
      const product = findProductDataByVariant(selectedVariantId)

      updateButtons(selectedVariantId, product)
    })

    setTimeout(() => {
      if ($giftCheckbox.is(':checked')) {
        $giftCheckbox.trigger('change')
      }
    }, 250)

    $($learnMoreBtn).on('click', (e) => {
      const $target = $(e.currentTarget)
      const spinner = $target.siblings('[data-element="spinner"]')
      $(spinner).removeClass('hidden')
      $target.addClass('hidden')
      $modalData.empty()

      const variantId = $target
        .closest('[data-component="product-item"]')
        .data('variant-id')
      const product = findProductDataByVariant(variantId)

      fetch(product.handle)
        .then((response) => response.text())
        .then((html) => {
          const parser = new DOMParser()
          const doc = parser.parseFromString(html, 'text/html')
          const content = doc.querySelector('main')

          if (content) {
            $modalData.html(content.innerHTML)
            $modal.attr('aria-hidden', 'false')

            const isPuraPlus =
              variantId == 44238775255140 ||
              variantId == 44238775287908 ||
              variantId == 41779671367789 ||
              variantId == 41779671400557

            if (isPuraPlus) {
              const $matchingSwatch = $modal
                .find('input[type="radio"][name="color"]')
                .filter(
                  (i, option) =>
                    $(option).attr('data-value') === variantId.toString()
                )
              if ($matchingSwatch.length) {
                setTimeout(() => {
                  $matchingSwatch.prop('checked', true).trigger('change')
                }, 200)
              }
            }
            const $videoElements = $modal
              .find('[data-element="slides"]')
              .find('[data-element="video"] video')

            if (window.Pura.isInApp) {
              disableVideoFullScreen()
            } else {
              $videoElements.each((index, video) => {
                const $video = $(video)
                const videoSrc = $video.attr('data-src')
                const $source = $video.find('source')
                const sourceSrc = $source.attr('data-src')

                if (videoSrc && sourceSrc) {
                  $video.attr('src', videoSrc)
                  $source.attr('src', sourceSrc)
                  video.load()
                }
              })
            }

            const $formInModal = $modal.find('[data-component="product-form"]')
            const $addToCartBtn = $(
              `<button class="button-outline w-full" data-element="bys-add-to-cart" data-action="add-variant-to-cart" data-variant="${variantId}">Add to cart</button>`
            )
            $formInModal.replaceWith($addToCartBtn)
            addToCartButton()
            updateSectionImages()

            const $reviewsAnchor = $modalData
              .find('[data-element="reviews"]')
              .closest('a')

            if ($reviewsAnchor.length) {
              const reviewContent = $reviewsAnchor.html()
              $reviewsAnchor.replaceWith(
                `<div class="flex items-center gap-1" data-element="reviews">${reviewContent}</div>`
              )
            }

            const $swatches = $modal.find(
              '[data-component="color-swatches"] input[type="radio"]'
            )
            if ($swatches) {
              handleSwatchClick($swatches)
            }

            $addToCartBtn.on('click', () => {
              $modal.attr('aria-hidden', 'true')
            })

            $('[data-component="slider"]').each(function sliderInit() {
              reInitSliders($(this))
            })

            $(
              '[data-action="hide-modal"], [data-action="add-variant-to-cart"]'
            ).on('click', () => {
              const $activeProduct = $(
                '[data-component="product-item"].border-default'
              )
              const handle = $activeProduct.data('handle')
              if (handle == 'pura-plus') {
                const $swatch = $activeProduct.find(
                  'input[type="radio"][name="color"]'
                )
                if ($swatch.length) {
                  const $firstVariant = $swatch.first()
                  $firstVariant.prop('checked', true).trigger('change').focus()
                }
                const mobileImages = $(
                  '[data-handle="pura-plus"] [data-element="mobile-image"] img'
                )
                mobileImages.eq(0).removeClass('hidden')
                mobileImages.eq(1).addClass('hidden')
              }
            })

            setTimeout(() => {
              $(spinner).addClass('hidden')
              $target.removeClass('hidden')
              reObserveStickyBar()
            }, 1000)
          } else {
            $modalData.html('<p>Error: Content not found.</p>')
          }
        })
        .catch(() => {
          $modalData.html('<p>Error loading content.</p>')
          $modal.attr('aria-hidden', 'false')
        })
    })
  })
}
