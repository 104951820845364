import $ from 'cash-dom'
import { cart } from './cart'

class AccountPanel {
  constructor() {
    this.panel = $('#account-panel')
    this.signUpPanel = $('#account-sign-up-panel')
    this.resetPasswordPanel = $('#account-reset-password-panel')
    this.verifyPanel = $('#account-verify-panel')
    this.open = false

    this.bindUI()
  }

  bindUI() {
    $('[data-action="goto-cart-panel"]').on('click', (e) => {
      e.preventDefault()
      this.hide()
      cart.show()
    })

    $('[data-action="show-account-panel"]').on('click', (e) => {
      e.preventDefault()
      e.target.setAttribute('aria-expanded', 'true')
      this.show()
    })

    $('[data-action="hide-account-panel"]').on('click', (e) => {
      e.preventDefault()
      this.hide()
    })

    $('[data-action="show-create-account-subpanel"]').on('click', (e) => {
      e.preventDefault()
      this.signUpPanel.attr('aria-hidden', 'false')
      this.show()
      this.signUpPanel.find('#firstName').focus()
    })

    $('[data-action="hide-create-account-subpanel"]').on('click', (e) => {
      e.preventDefault()
      this.signUpPanel.attr('aria-hidden', 'true')
    })

    $('[data-action="show-reset-password-subpanel"]').on('click', (e) => {
      e.preventDefault()
      let signInEmail = document.getElementById('signin-email').value || false
      let sendResetEmailInput = document.getElementById('send-reset-email')
      if (signInEmail) {
        sendResetEmailInput.value = signInEmail
        var changeEvent = new Event('change', { bubbles: true })
        sendResetEmailInput.dispatchEvent(changeEvent)
      }
      this.resetPasswordPanel.attr('aria-hidden', 'false')
      this.resetPasswordPanel.find('#send-reset-email').focus()
    })

    $('[data-action="hide-reset-password-subpanel"]').on('click', (e) => {
      e.preventDefault()
      this.resetPasswordPanel.attr('aria-hidden', 'true')
    })

    $('[data-action="show-verify-subpanel"]').on('click', (e) => {
      e.preventDefault()
    })

    $('[data-action="hide-verify-subpanel"]').on('click', (e) => {
      e.preventDefault()
      this.verifyPanel.attr('aria-hidden', 'true')
    })

    this.panel.find('nav a').on('click', (e) => {
      this.hide()
    })

    this.chechHash()
  }

  show() {
    this.panel.attr('aria-hidden', 'false')
    this.open = true
  }

  hide() {
    this.panel.attr('aria-hidden', 'true')
    this.signUpPanel.attr('aria-hidden', 'true')
    this.resetPasswordPanel.attr('aria-hidden', 'true')
    this.verifyPanel.attr('aria-hidden', 'true')
    this.open = false
    $('[aria-controls="account-panel"][aria-expanded="true"]').focus()
    $('[aria-controls="account-panel"]').attr('aria-expanded', 'false')
  }

  chechHash() {
    if (window.location.hash == '#show-create-account-subpanel') {
      setTimeout(() => {
        this.show()
      }, 1000)
    }
  }

  showVerifyPanel(email, password) {
    // Copy over email and password from previous form
    this.verifyPanel.find('#verification-email').val(email)
    this.verifyPanel.find('#verification-password').val(password)

    this.verifyPanel.attr('aria-hidden', 'false')
    this.verifyPanel.find('#verification-code').focus()
  }
}

const accountPanel = new AccountPanel()
var Pura = window.Pura || {}

Pura.accountPanel = accountPanel

export { accountPanel }
