import $ from 'cash-dom'
import { arraysAreEqual } from '../utilities/toolkit'

const calculateVariant = (form) => {
  const options = form.find('[data-element="product-option"]')
  let values = []
  let variants

  if ($('[data-component="product-form-modal"]').length > 0) {
    const productId = $('[data-component="product-form-modal"]').attr(
      'data-product-id'
    )
    const productData = $(`#product-data-${productId}`).html()

    variants = JSON.parse(productData)
  } else {
    variants = window.Pura.product.variants
  }
  options.each((i) => {
    values.push(decodeURIComponent(options[i].value))
  })

  for (let i = 0; i < variants.length; ++i) {
    if (arraysAreEqual(variants[i].options, values)) {
      return variants[i]
    }
  }

  return false
}

const updateVariantInURL = (variantId) => {
  const params = `?variant=${variantId}`
  window.history.replaceState({}, null, `${window.location.pathname}${params}`)
}

const updateVariantIdInForm = (form, variant) => {
  const idField = form.find('[name="id"]')
  idField.attr('value', variant.id)
}

const updateAddToCartButton = (form, variant) => {
  const button = form.find('[data-element="add-to-cart-button"]')
  button.attr('data-state', '')

  if (variant == false) {
    button.prop('disabled', true)
  } else if (variant.available) {
    button.prop('disabled', false)
    button.attr('data-state', '')
  } else {
    button.prop('disabled', true)
    button.attr('data-state', 'out-of-stock')
  }
}

const updatePrice = (variant) => {
  const priceElements = $('[data-content="product-price"]')
  let price = (variant.price / 100).toFixed(2).replace('.00', '')
  price = window.Pura.currency.format.replace('{{amount}}', price)
  priceElements.html(price)
}

$('[data-element="product-option"]').on('change', (e) => {
  e.preventDefault()

  const form = $(e.target.closest('[data-component="product-form"]'))
  const variant = calculateVariant(form)

  if (variant != false) {
    updateVariantInURL(variant.id)
    updateVariantIdInForm(form, variant)
    updatePrice(variant)
  }

  updateAddToCartButton(form, variant)
})

const handleSwatchClick = ($colorSwatches) => {
  $($colorSwatches).on('change', function updateOptions() {
    const $this = $(this)
    const $selectedOption = $this.val()
    const $optionName = $this.next('[data-element="option-name"]')

    const defaultSelector = $('[data-element="product-option"]')
    defaultSelector.val($selectedOption).trigger('change')

    const variantId = $this.data('value')

    const options = $('[data-element="option-name"]')
    options.addClass('hidden')
    $optionName.removeClass('hidden')

    if ($selectedOption?.toLowerCase() === 'black') {
      $('#build-your-set-cta').addClass('hidden')
      $('#build-your-set-cta-variant-2').removeClass('hidden')
    } else {
      $('#build-your-set-cta').removeClass('hidden')
      $('#build-your-set-cta-variant-2').addClass('hidden')
    }

    if (Pura.template.suffix == 'bys-landing-page') {
      $('[data-element="bys-add-to-cart"]').data('variant', variantId)
    }
    const matchingSlider = $(
      `[data-component="product-slider-gallery-${variantId}"]`
    )
    $('[data-component^="product-slider-gallery"]').hide()

    if (matchingSlider.length) {
      const slider = $(matchingSlider).find(
        '[data-component="variant-gallery-sliders"]'
      )

      if (slider.hasClass('slick-initialized')) {
        slider.slick('unslick')
      }

      slider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 0,
        arrows: true,
        asNavFor: `.thumb-slider-${variantId}`,
        prevArrow: $(`[data-action="previous"]`),
        nextArrow: $(`[data-action="next"]`),
      })

      matchingSlider.show().removeClass('hidden')
    }
  })
}

$(document).ready(() => {
  const $colorSwatches = $(
    '[data-component="color-swatches"] input[type="radio"]'
  )
  if ($colorSwatches) {
    handleSwatchClick($colorSwatches)
  }
})

export { handleSwatchClick }
