const reviewComponent = (item) => {
  let roundedScore = ''

  if (item.product_score) {
    roundedScore = Math.round(item.product_score * 10) / 10
  }

  if (!item.total_reviews) {
    item.total_reviews = 0
  }

  const fullStars = Math.floor(roundedScore)
  const halfStar = roundedScore % 1 >= 0.5 ? 1 : 0
  const emptyStars = 5 - fullStars - halfStar

  const fullStarSVG = `
    <svg class="svg-star-full" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <title>star full</title>
      <path d="M8 1L9.5716 5.83688H14.6574L10.5429 8.82624L12.1145 13.6631L8 10.6738L3.8855 13.6631L5.4571 8.82624L1.3426 5.83688H6.4284L8 1Z" fill="currentColor"/>
    </svg>`

  const halfStarSVG = `
    <svg className="svg-star-half" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32" fill="none">
      <title>half star</title>
      <path d="M16 4.42705L18.4299 11.9055C18.5303 12.2145 18.8183 12.4238 19.1432 12.4238H27.0065L20.645 17.0457C20.3821 17.2367 20.2721 17.5752 20.3725 17.8842L22.8024 25.3627L16.4408 20.7408C16.178 20.5498 15.822 20.5498 15.5592 20.7408L9.19759 25.3627L11.6275 17.8842C11.7279 17.5752 11.6179 17.2367 11.355 17.0457L4.99347 12.4238H12.8568C13.1817 12.4238 13.4697 12.2145 13.5701 11.9055L16 4.42705Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
      <path d="M16.1982 20.5V5.5L13.1982 12.5H6.19824L11.6982 17.5L9.69824 24.5L16.1982 20.5Z" fill="currentColor"/>
    </svg>`

  const emptyStarSVG = `
    <svg class="svg-star-empty" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <title>empty star</title>
      <path d="M8 2.61803L9.09607 5.99139C9.16301 6.1974 9.35499 6.33688 9.5716 6.33688H13.1186L10.249 8.42173C10.0738 8.54905 10.0004 8.77474 10.0674 8.98075L11.1634 12.3541L8.29389 10.2693C8.11865 10.1419 7.88135 10.1419 7.70611 10.2693L4.83656 12.3541L5.93263 8.98075C5.99957 8.77474 5.92624 8.54905 5.75099 8.42173L2.88145 6.33688H6.4284C6.64502 6.33688 6.83699 6.1974 6.90393 5.99139L8 2.61803Z" stroke="#1D1B1B" stroke-linejoin="round"/>
    </svg>`

  let starsHTML = ''
  for (let i = 0; i < fullStars; i++) {
    starsHTML += fullStarSVG
  }
  if (halfStar) {
    starsHTML += halfStarSVG
  }
  for (let i = 0; i < emptyStars; i++) {
    starsHTML += emptyStarSVG
  }

  return `<div data-element="reviews" class="flex flex-wrap items-center gap-1">
      <div
        role="img"
        aria-label="Rating: ${roundedScore} stars out of 5"
        class="leading-0">
          ${starsHTML}
      </div>
      <p class="text-caption speak-none mb-0">
        <span data-action="review-count">${item.total_reviews}</span> Reviews
      </p>
    </div>`
}

export { reviewComponent }
