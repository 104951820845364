
/*
{
   "site_id":"5ff7a6a1-dcbb-430f-99fa-5e7636e2e3a7",
   "form_name":"Cory Test",
   "fields":[
      {
         "label":"email",
         "value":"cory.schulz+3@pura.com",
         "type":"email"
      }
   ]
}
*/

const submitShogunForm = (data) => {

  return fetch('https://forms.getshogun.com/api/forms/contact/submit', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
  .then(response => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response
  })
  .catch(error => {
    console.error(error)
  });
}


export { submitShogunForm }