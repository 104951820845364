import $ from 'cash-dom'
import './mobile-menu'

class MobileHeader {
  constructor() {
    this.open = false
    this.mobileMenuButton = $('#mobile-menu-button')
    this.mobileMenu = $('#mobile-menu')
    this.bindUI()
  }

  bindUI() {
    $('[data-action="show-mobile-menu"]').on('click', (e) => {
      e.preventDefault()
      e.target.setAttribute('aria-expanded', 'true')
      this.show()
    })

    $('[data-action="hide-mobile-menu"]').on('click', (e) => {
      e.preventDefault()
      this.hide()
    })

    this.mobileMenu.find('a').on('click', (e) => {
      this.hide()
    })
  }

  show() {
    this.mobileMenu.attr('aria-hidden', 'false')
    this.open = true
  }

  hide() {
    this.mobileMenuButton.attr('aria-expanded', 'false')
    this.mobileMenu.attr('aria-hidden', 'true')
    this.open = false
  }
}

const mobileHeader = new MobileHeader()

let Pura = window.Pura || {}
Pura.mobileHeader = mobileHeader

export { mobileHeader }
