import $ from 'cash-dom'
import { slideOpen, slideClosed } from '../utilities/animations'

window.document.addEventListener('click', (e) => {
  let segment = window.analytics

  if (!e.target.closest('[data-action="toggle-expandable-content"]')) return

  e.preventDefault()

  const component = $(e.target).closest('[data-component="expandable-content"]')
  const content = component.find('[data-element="expandable-content"]')
  const eventName = component.attr('data-event-name')
  const isExpanded = component.attr('aria-expanded')

  if (isExpanded === 'false') {
    component.attr('aria-expanded', 'true')
    slideOpen(content[0])
    if (eventName !== 'undefined') {
      segment.track(eventName + ' clicked', content[0])
    }
  } else {
    component.attr('aria-expanded', 'false')
    slideClosed(content[0])
  }
})
