

const getNewPackingId = (cart, ignore) => {
	const items = cart.items
	let idExists;
	let packingId;

	// support up to 100 unique IDs
	for (let i=0; i < 26; ++i){
		idExists = false;
		packingId = `[${String.fromCharCode(65 + i)}]`

		if (packingId == ignore) continue;

		// check every item to see if this ID exists
		for (let k=0; k < items.length; ++k){
			if (items[k].properties && 
					items[k].properties._pura_packing_id == packingId){
				idExists = true
				break
			}
		}

		// return index that doesn't exist
		if (!idExists){
			return packingId
		}
	}
	
	return "1"
}

Pura.cart.getNewPackingId = getNewPackingId;


export { getNewPackingId }