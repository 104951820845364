import $ from 'cash-dom'
import { setCookie } from '../utilities/cookies'
import { getURLParam } from '../utilities/toolkit'

const isHomepage = Pura.template.name === 'index'
let formType = window.location.hash || false
const popup = document.querySelector('[data-section-id="popup"]')
const content = document.querySelector('#popup-content')
const subscribedToNewsletter = getURLParam('customer_posted')
const testingParam = getURLParam('disable_newsletter')

const checkDeviceType = () => {
  const mobileWidthThreshold = 768
  const screenWidth = window.innerWidth
  return screenWidth <= mobileWidthThreshold ? 'mobile' : 'desktop'
}

const toggleContentForMobile = () => {
  const deviceType = checkDeviceType()
  if (!popup.classList.contains('hidden')) {
    // Only run if popup is visible
    if (deviceType === 'mobile') {
      content.classList.remove('translate-y-full')
      content.classList.add('translate-y-0')
    } else {
      content.classList.add('translate-y-full')
      content.classList.remove('translate-y-0')
    }
  }
}

const showNewsletterPopup = (time = 1000) => {
  setTimeout(function () {
    if (popup && testingParam !== 'true') {
      popup.setAttribute('aria-hidden', 'false')
      popup.classList.remove('hidden')

      const focusTrapStart = document.querySelector(
        '#sms-email-section focus-trap-start'
      )
      const x = window.scrollX
      const y = window.scrollY
      window.addEventListener('resize', toggleContentForMobile)

      if (focusTrapStart) {
        setTimeout(() => {
          focusTrapStart.focus()
          toggleContentForMobile()
          window.scrollTo(x, y)
        }, 200)
      }
      setCookie('signup-popup', 1, 7)
    }
  }, time)
}

const closePopup = () => {
  popup.classList.add('hidden')
  window.removeEventListener('resize', toggleContentForMobile)
}

$('[data-action="close-popup"]').on('click', closePopup)

const previouslySubscribed = (formType) => {
  $(formType).hide()
  $(formType)
    .parentsUntil('section')
    .find('[data-component="previously-subscribed"]')
    .show()
}

if (isHomepage && formType === '#newsletter-popup') {
  showNewsletterPopup()
}

if (formType === '#newsletter-footer' || formType === '#newsletter-popup') {
  if (!subscribedToNewsletter) {
    previouslySubscribed(formType)
  }
}

$('.input-group-radio input[type="radio"]').on('change', () => {
  const form = $(this).closest('form')
  const emailForm = form.find('[data-component="email-form"]')
  const smsForm = form.find('[data-component="sms-form"]')
  const smsTerms = form
    .parentsUntil('section')
    .find('[data-element="sms-legal-terms"]')

  if (this.value === 'email') {
    emailForm.removeClass('hidden')
    smsForm.addClass('hidden')
    smsTerms.addClass('hidden')
  } else {
    emailForm.addClass('hidden')
    smsForm.removeClass('hidden')
    smsTerms.removeClass('hidden')
  }
})

$('[data-element="braze-sign-up-form"]').on('click', function (e) {
  const user = braze.getUser()
  let form = $(e.target.closest('form'))
  let email = form.find('[name="contact[email]"]').val()
  let phone = form.find('[name="contact[phone]"]').val()

  if (email) {
    user.setEmail(email)
    user.setCustomUserAttribute('shopify_email_capture', true)
  }

  if (phone) {
    user.setPhoneNumber(phone)
    user.addToSubscriptionGroup(
      Pura.environment.production
        ? '4d83b940-8dfc-4bef-a20a-d378fd5afcb8'
        : '97cfbca1-9bbf-403a-9ba0-31a3f5dcf8b2'
    )
    user.setCustomUserAttribute('shopify_sms_capture', true)
  }

  if (!email) {
    e.preventDefault()
    let section = form.parentsUntil('section')
    let smsLegalTerms = section.find('[data-element="sms-legal-terms"]')
    let smsSuccessMessage = section.find('[data-component="sms-subscribed"]')

    if (phone) {
      $(form).addClass('hidden')
      $(smsLegalTerms).addClass('hidden')
      $(smsSuccessMessage).show()
    }
  }
})

export { showNewsletterPopup }
