import $ from "cash-dom";
// import { AddressForm } from '@shopify/theme-addresses'


$('[data-action="show-recover-pw-panel"]').click(function(e) {
  e.preventDefault();
  $('[data-element="recover-pw-panel"]').attr('aria-hidden', 'false' )
  $(e.currentTarget).hide();
})



$('[data-element="account-nav-item"]').on('click', function(e) {
  e.preventDefault();
  $('[data-element="account-nav-item"]').attr('aria-selected', 'false')
  e.currentTarget.setAttribute('aria-selected', 'true')
});


$('[data-component="delete-address-form"]').on('submit', (e) => {
  if (!window.confirm('Are you sure you want to delete this address?')) {
    e.preventDefault();
  }
});

// Go to address panel if url is /account/addresses
if (Pura.template != null && Pura.template.name == "addresses"){
  $('[data-element="account-nav-item"][data-panel-name="account-details-panel"]').trigger('click');
}



// let addressFormFields = document.querySelectorAll('[data-address="root"] [data-address-fields]')
// addressFormFields.forEach( function(obj, i){
//   new AddressForm(obj, 'en');
// })
