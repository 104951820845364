import $ from 'cash-dom'

let cartSlider = $('#cart-section-slider')

const buildCartSection = (cart) => {
  let items = cart.items
  $(cartSlider).slick('removeSlide', null, null, true)

  for (let i = 0; i < items.length; ++i) {
    let item = items[i]
    $(cartSlider).slick(
      'slickAdd',
      `<div class="pr-3">
        <img class="w-[145px] h-[177px] md:w-[20vw] md:h-100"
        src="${item.image}" alt="${item.title}" />
      </div>`
    )
  }
}
export { buildCartSection }
