import $ from 'cash-dom'
import fetch from 'unfetch'
import serialize from 'form-serialize'
import { generateUID } from '../utilities/toolkit'

const getCart = () =>
  fetch('/cart.json', {
    crossDomain: true,
    credentials: 'same-origin',
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response.json()
    })
    .catch((error) => {
      console.error(error)
    })

const addVariantToCart = (
  id,
  selling_plan,
  quantity = 1,
  properties = null
) => {
  return fetch('/cart/add.json', {
    crossDomain: true,
    method: 'POST',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, selling_plan, quantity, properties }),
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return getCart()
    })
    .catch((error) => {
      console.error(error)
    })
}

const addFormToCart = (form) => {
  const data = serialize(form, { hash: true })
  let variantId
  let includedProducts = []
  let groupID = generateUID() || Date.now()

  // Build included products array
  for (let i = 1; i < 4; ++i) {
    variantId = data[`include_product_${i}`]

    if (variantId != undefined) {
      includedProducts.push({
        id: variantId,
        quantity: data.quantity,
        properties: {
          _pura_hidden_in_cart: true,
          _pura_group_id: groupID,
          _pura_group_role: 'child',
        },
      })
      delete data[`include_product_${i}`]
    }
  }

  // Set properties
  if (includedProducts.length > 0) {
    data.properties = data.properties || {}
    data.properties['_pura_group_id'] = groupID
    data.properties['_pura_group_role'] = 'parent'
  }

  // Consolidate products
  includedProducts.push(data)

  return fetch('/cart/add.json', {
    crossDomain: true,
    method: 'POST',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ items: includedProducts }),
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return getCart()
    })
    .catch((error) => {
      console.error(error)
    })
}

/*
Assumes you are passing in items object
{
  items: [
    {
      id: variantId,
      quantity: 1,
      properties: {
        _pura_hidden_in_cart: true,
        _pura_group_id: groupID,
        _pura_group_role: 'parent'
      }
    },
    {
      id: variantId,
      quantity: 2
    }
  ]
}
*/
const addItemsToCart = (items) => {
  return fetch('/cart/add.json', {
    crossDomain: true,
    method: 'POST',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(items),
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return getCart()
    })
    .catch((error) => {
      console.error(error)
    })
}

const displayErrorMessage = (message) => {
  const $errorMessage = $('[data-element="error-message"]')
  $errorMessage.text(message)
  $errorMessage.css({
    display: 'block',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  })

  setTimeout(() => {
    $errorMessage.css('opacity', 1)
  }, 10)

  setTimeout(() => {
    $errorMessage.css('opacity', 0)

    setTimeout(() => {
      $errorMessage.css('display', 'none')
    }, 300)
  }, 5000)
}

const handleFetch = async (response) => {
  if (!response.ok) {
    const data = await response.json()
    const errorMessage =
      data.errors || 'An error occurred while updating the cart'
    displayErrorMessage(errorMessage)
    throw new Error(errorMessage)
  }
  return response.json()
}

const updateCart = async (updateObj) => {
  try {
    const response = await fetch('/cart/update.json', {
      method: 'POST',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updateObj),
    })
    return await handleFetch(response)
  } catch (error) {
    console.error('Cart update failed:', error.message)
    return { errors: error.message }
  }
}

const updateCartItem = async (key, quantity) => {
  try {
    const response = await fetch('/cart/change.json', {
      method: 'POST',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: key, quantity }),
    })
    return await handleFetch(response)
  } catch (error) {
    console.error('Cart item update failed:', error.message)
    return { errors: error.message }
  }
}
const updateCartAttribute = async (key, value) => {
  const data = { attributes: { [key]: value } }

  try {
    const response = await fetch('/cart/update.json', {
      method: 'POST',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })

    if (!response.ok) {
      const error = new Error('updateCartAttribute error')
      error.response = response
      throw error
    }

    return await response.json()
  } catch (error) {
    console.error('Error updating cart attribute:', error)
    return null
  }
}

const updateCartAttributes = (attributes) => {
  return fetch('/cart/update.json', {
    crossDomain: true,
    method: 'POST',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ attributes: attributes }),
  })
    .then((response) => {
      if (!response.ok) {
        console.error('updateCartAttribute error', response.statusText)
      }
      return response.json()
    })
    .catch((error) => {
      console.error(error)
    })
}

export {
  getCart,
  addVariantToCart,
  addFormToCart,
  addItemsToCart,
  updateCartItem,
  updateCartAttribute,
  updateCartAttributes,
  updateCart,
}
