import $ from 'cash-dom'

const displayCustomerPoints = (customer) => {
  let pointsBalance = customer.pointsBalance
  let pointsValue = Math.floor(pointsBalance / 40)

  $('[data-action="points-balance"]').html(pointsBalance)
  $('[data-action="customer-points-value"]').html(pointsValue)
}
const displayReferralData = (customer) => {
  let referralLink = customer.referralLink
  let referralCode = customer.referralCode

  if (referralCode !== '') {
    $('#referal-social-links').show()
    $('#referal_link').attr('data-text', referralLink)
  }
}

export { displayCustomerPoints, displayReferralData }
