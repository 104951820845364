import $ from 'cash-dom'
import { getCollection } from '../resources/shopify-collection'
import { addToCartButton } from '../components/product-form'
import { observeProducts } from '../tracking/view-item-list'
import { getCollectionProductPricing } from '../components/sitewide-discount'

let collectionHandle = false
let collectionProducts = document.getElementById('collection-products')
let collectionFilters = document.querySelector('[data-element="filter-panel"]')
let collectionPageCount = 1
let filterCount = ''
const paginationButton = document.getElementById('collection-pagination-button')
const spinner = document.getElementById('spinner')
let sortOption = false
let tags = ''

if (Pura.collection) {
  // set handle
  if (Pura.collection.handle) {
    collectionHandle = Pura.collection.handle
  }
  // show oos items on load >= 20 skus
  if (paginationButton && paginationButton.classList.contains('hidden')) {
    const collectionItems = document.getElementById('collection-products')
    const outOfStockItems = document.getElementById('out-of-stock')

    if (outOfStockItems) {
      $(collectionItems).append(outOfStockItems.innerHTML)
    }
  }
}

const buildCollectionURL = () => {
  if (!collectionHandle) return

  let url = `/collections/${collectionHandle}/${tags}?`

  if (sortOption != false) {
    url += 'sort_by=' + sortOption + '&page=' + collectionPageCount
  } else {
    url += 'page=' + collectionPageCount
  }

  return url
}

const buildFilterBadge = (count) => {
  if (count && count > 0) {
    $('[data-element="filter-count"]').html('(' + count + ')')
    $('[data-component="filter-badge"]').removeClass('hidden')
    return
  }

  $('[data-component="filter-badge"]').addClass('hidden')
}
const setFreeProductPrice = () => {
  const container = document.getElementById('collection-products')
  const thumbnails = container.querySelectorAll('.product-thumbnail')

  thumbnails.forEach((thumb) => {
    const scriptTag = thumb.querySelector('script[type="application/json"]')
    const priceElement = thumb.querySelector('[data-content="product-price"]')

    try {
      const discountedPrice = 0
      const productData = JSON.parse(
        scriptTag.textContent || scriptTag.innerText
      )
      let originalPrice = productData.price / 100

      let innerHTMLContent = ''
      if (originalPrice > discountedPrice) {
        if (productData.hasSub) {
          originalPrice = (originalPrice * 0.8).toFixed(2)
        }
        innerHTMLContent += `<span class="line-through opacity-50" aria-label="original price is: $${originalPrice}">$${originalPrice}</span>`
      }

      innerHTMLContent += `<span data-tag="$${discountedPrice}" aria-label="subscription price is: $${discountedPrice}">FREE</span>`

      priceElement.innerHTML = innerHTMLContent
      productData.price = discountedPrice
      scriptTag.textContent = JSON.stringify(productData, null, 2)
    } catch (e) {
      console.error('Error processing a product script tag:', e)
    }
  })
}

const setDiscountProductPrice = () => {
  const container = document.getElementById('collection-products')
  const thumbnails = container.querySelectorAll('.product-thumbnail')

  thumbnails.forEach((thumb) => {
    const scriptTag = thumb.querySelector('script[type="application/json"]')
    const priceElement = thumb.querySelector('[data-content="product-price"]')
    try {
      const productData = JSON.parse(
        scriptTag.textContent || scriptTag.innerText
      )
      let originalPrice = productData.price / 100
      let price = originalPrice
      if (productData.hasSub) {
        price = parseFloat((originalPrice * 0.8).toFixed(2))
      }

      let discountedPrice = getCollectionProductPricing(
        price,
        Pura.product.type
      )

      if (priceElement) {
        let innerHTMLContent = `<span data-tag="$${discountedPrice.replace(
          '.00',
          ''
        )}" aria-label="subscription price is: $${discountedPrice.replace(
          '.00',
          ''
        )}">$${discountedPrice.replace('.00', '')}</span>`

        if (price > discountedPrice) {
          innerHTMLContent += `<span class="line-through opacity-50" aria-label="original price is:$${originalPrice}">$${originalPrice}</span>`
        }

        priceElement.innerHTML = innerHTMLContent
      }
    } catch (e) {
      console.error('Error processing a product script tag:', e)
    }
  })
}

const reloadCollection = (reloadFilterPanel = false, callback) => {
  collectionPageCount = 1
  let url = buildCollectionURL()

  $('[data-action="search-bogo"]').val('')

  getCollection(url)
    .then((res) => {
      const parser = new DOMParser()
      const doc = parser.parseFromString(res, 'text/html')
      const oosProducts = doc.getElementById('out-of-stock')
      const pagination = doc.getElementById('collection-pagination-button')
      const products = doc.getElementById('collection-products')
      const filterPanel = doc.querySelector('[data-element="filter-panel"]')

      if (pagination && !pagination.classList.contains('hidden')) {
        $('#collection-pagination-button').removeClass('hidden')
      } else {
        $('#collection-pagination-button').addClass('hidden')
        if (oosProducts != null) {
          $(products).append(oosProducts.innerHTML)
        }
      }
      // Update DOM
      if (products == undefined || products.children.length == 0) {
        collectionProducts.innerHTML = `<p class="col-span-2">
        There are no results using these filters. <br>
        Please select different filters.
      </p>`
      } else {
        collectionProducts.innerHTML = products.innerHTML
      }

      // reload filters panel when changing collection urls
      if (reloadFilterPanel) {
        collectionFilters.innerHTML = filterPanel.innerHTML
      }

      // Only update URL if it is a collection page
      if (location.pathname.indexOf('collection') != -1) {
        window.history.pushState({}, '', url)
      }
      addToCartButton()

      // transform pricing if on BYS on offering free products
      if (Pura.BYS) {
        if (
          Pura.BYS.panel_2_fragrances_are_free &&
          Pura.BYS.panel_2_collection_handle == url.split('/')[2]
        ) {
          setFreeProductPrice()
        } else if (Pura.cart.enable_sitewide_discount) {
          setDiscountProductPrice()
        }
      }

      if (callback && typeof callback === 'function') {
        callback()
      }
    })
    .catch((error) => {
      console.error(error)
    })
}

const loadNextPage = () => {
  collectionPageCount++
  let nextPageURL = buildCollectionURL()
  $(spinner).removeClass('hidden')

  getCollection(nextPageURL)
    .then((res) => {
      const parser = new DOMParser()
      const doc = parser.parseFromString(res, 'text/html')
      const newProductCount =
        doc.getElementsByClassName('product-thumbnail').length
      if (newProductCount == 0) {
        $('#collection-pagination-button').addClass('hidden')
        return
      }
      const newProducts = doc.getElementById('collection-products').innerHTML
      const outOfStock = $('#out-of-stock').html()
      const pagination = doc.getElementById('collection-pagination-button')

      // Update DOM
      $(collectionProducts).append(newProducts)
      addToCartButton()

      // transform pricing if on BYS on offering free products
      if (Pura.BYS) {
        if (
          Pura.BYS.panel_2_fragrances_are_free &&
          Pura.BYS.panel_2_collection_handle == nextPageURL.split('/')[2]
        ) {
          setFreeProductPrice()
        } else if (Pura.cart.enable_sitewide_discount) {
          setDiscountProductPrice()
        }
      }
      if (pagination && !pagination.classList.contains('hidden')) {
        $('#collection-pagination-button').removeClass('hidden')
      } else {
        $('#collection-pagination-button').addClass('hidden')
        if (outOfStock != null) {
          $(collectionProducts).append(outOfStock)
        }
      }
      $(spinner).addClass('hidden')
    })
    .catch((error) => {
      console.error(error)
      $(spinner).addClass('hidden')
    })
}

const clearFilters = (reload = true) => {
  collectionPageCount = 1
  tags = ''

  $('[data-component="filter-badge"]').addClass('hidden')
  $('[data-element="filter-item"] input:checked').prop('checked', false)

  if (reload) {
    reloadCollection()
  }
}

const setCollectionHandle = (newHandle, callback) => {
  collectionHandle = newHandle
  clearFilters(false)
  reloadCollection(true, (dataFromReload) => {
    if (callback && typeof callback === 'function') {
      callback(dataFromReload)
    }
  })
}

const setFilterCount = (count) => {
  filterCount = count
  buildFilterBadge(filterCount)
}

const setSortOption = (newOption) => {
  sortOption = newOption.trim()
  if (sortOption == '') sortOption = false

  reloadCollection()
}

const setTags = (newtags) => {
  tags = newtags
  reloadCollection()
}

//set inital filters
if (Pura.collection && Pura.collection.current_tags.length > 0) {
  tags = Pura.collection.current_tags.join('+')
  setFilterCount(Pura.collection.current_tags.length)
}

if (
  Pura.template.suffix === 'BYB-v3-v4' ||
  Pura.template.suffix === 'BYB-v3-v4-code'
) {
  sortOption = 'manual'
}

if (Pura.collection) {
  const loadMoreObserver = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        if (
          sortOption == false &&
          window.location.search.includes('best-selling')
        ) {
          sortOption = 'best-selling'
        }
        loadNextPage()
        observeProducts()
      }
    },
    {
      rootMargin: '500px',
    }
  )

  if (paginationButton) {
    loadMoreObserver.observe(paginationButton)
  }
}

export {
  clearFilters,
  reloadCollection,
  setCollectionHandle,
  setFilterCount,
  setSortOption,
  setTags,
  setFreeProductPrice,
  setDiscountProductPrice,
}
