import $ from 'cash-dom'

const onSuccessRedeem = () => {
  $('#reward-subscription').removeClass('hidden')
  $('#existing-subscription').hide()
}

const onErrorRedeem = () => {
  $('#subscription-modal').attr('data-state', 'hidden')
  $('#no-existing-sub-modal')
    .attr({ 'data-state': 'show', 'aria-hidden': 'false' })
    .show()
}

export { onSuccessRedeem, onErrorRedeem }
