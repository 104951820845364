import $ from 'cash-dom'
import { mobileHeader } from './header-mobile'

$('[data-action="open-modal"]').on('click', function (e) {
  e.preventDefault()
  $('modal-panel').attr('aria-hidden', 'true')

  let modalId = this.getAttribute('data-modal-id')
  let modal = $(`#${modalId}`)
  $('body').addClass('modal-blur')
  modal.attr({ 'aria-hidden': 'false', 'data-state': 'show' })
  modal.show()

  // Search panel gets some special treatment
  let searchInput = modal.find('[data-element="search-input"]')
  if (searchInput.length > 0) {
    searchInput[0].focus()
  }

  if (modalId == 'search-modal') {
    mobileHeader.hide()
  }
})

$('[data-action="close-modal"]').on('click', function (e) {
  e.preventDefault()
  closeModal(this)
  // pauseVideos(this)
})

$('[data-element="search-input"]').on('keydown', function (e) {
  if (e.key === 'Escape') {
    closeModal(this)
  }
})

const closeModal = (element) => {
  $('body').removeClass('modal-blur')
  $(element)
    .closest('[data-component="modal-panel"]')
    .attr('data-state', 'hidden')
}

const playVideos = (modalPanel) => {
  // Play an mp4 video if it has it
  let mp4Video = $(`#${modalId} video`)[0]
  if (mp4Video != null) {
    mp4Video.play()
  }

  let vimeoVideo = $(`#${modalId} .js-vimeo-video`)[0]
  if (vimeoVideo != null) {
    var player = new window.VimeoPlayer(vimeoVideo)
    player.play()
  }
}

const pauseVideos = (modalPanel) => {
  // Stop mp4 video if it has it
  let mp4Video = modalPanel.find('video')[0]
  if (mp4Video != null) {
    mp4Video.pause()
  }

  // Stop Vimeo video if it has it
  let vimeoVideo = modalPanel.find('.js-vimeo-video')[0]
  if (vimeoVideo != null) {
    var player = new window.VimeoPlayer(vimeoVideo)
    player.pause()
  }
}
