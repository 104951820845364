import fetch from 'unfetch'



const searchProducts = (query, view = "thumbnails") => {
  const url = `/search?q=${encodeURI(query)}*&type=product&view=${view}`;

  return fetch(url, {
      crossDomain: true,
      credentials: 'same-origin',
    })
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response.text();
    })
    .catch(error => {
      console.error(error)
    })
}



export { 
  searchProducts
} 
