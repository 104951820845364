import { datadogRum } from '@datadog/browser-rum'

datadogRum.init({
  applicationId: Pura.environment.production ? 'a472d657-19a2-4d80-b5f7-8654dce4eff2' : '3c4711b1-879c-445d-9652-366788321f21',
  clientToken: Pura.environment.production ? 'pub38db3cc767572d831a106766fa753e13' : 'pub1613d71aacfa194f77a8f5e93d73e8b8',
  site: 'datadoghq.com',
  service: Pura.environment.production ? 'web-prod' : 'web-stage',
  env: Pura.environment.production ? 'prod' : 'staging',
  version: '1.0.0',
  sessionSampleRate: Pura.environment.production ? 1 : 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
})

if (Pura.customer) {
  datadogRum.setUser({
    id: Pura.customer.key,
    name: `${Pura.customer.first_name} ${Pura.customer.last_name}`,
    email: Pura.customer.email,
  })
} else {
  datadogRum.removeUserProperty('name')
  datadogRum.removeUserProperty('id')
  datadogRum.removeUserProperty('email')
}
