const getKlevuBadge = (item) => {
  let badge = null

  if (item.tags != undefined) {
    let tags = item.tags.split(',')

    for (let tag of tags) {
      tag = tag.replace('[', '').replace(']', '').trim()
      if (tag == '__label Coming soon' || tag == '__label Notify me') {
        badge = 'Notify me'
        break
      } else if (tag == '__label Sold out') {
        badge = 'Sold out'
        break
      } else if (
        tag !== '__label Best seller' &&
        tag !== '__label New' &&
        tag !== '__label Seasonal'
      ) {
        if (tag.indexOf('__label') !== -1) {
          badge = tag.replace('__label', '')
          break
        }
      } else if (tag == '__label Best seller') {
        badge = 'Best seller'
      } else if (tag == '__label New') {
        badge = 'New'
      } else if (tag == '__label Seasonal') {
        badge = 'Seasonal'
      }
    }
  }
  return badge
}

const getKlevuCategoryBadge = (item) => {
  let categoryBadges = []
  let openAirTechBadge = null

  if (item.tags != undefined) {
    let tags = item.tags.split(',')

    for (let tag of tags) {
      tag = tag.replace('[', '').replace(']', '').trim()

      if (tag == 'Group_Home') categoryBadges.push('home')
      if (tag == 'Group_Car') categoryBadges.push('car')
      if (tag == 'Group_Sample') categoryBadges.push('sample')
      if (tag == 'type_Smartvial') categoryBadges.push('smart-vial')
      if (tag.includes('Openair'))
        openAirTechBadge = tag.replace('Openairtech_', 'Open Air Tech-')
    }
  }
  if (openAirTechBadge !== null) {
    categoryBadges.push(openAirTechBadge)
  }
  return categoryBadges
}

export { getKlevuBadge, getKlevuCategoryBadge }
