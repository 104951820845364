import $ from 'cash-dom';

let links = $('#tabs-tabVertical a');
let panels = $('.tab-content .tab-pane');


function toggleTableOfContents(query) {
    if (window.matchMedia('(' + query + ')').matches) {
        $('#tab-panel').css('display', 'block');   //Show
    }
    else {
        $('#tab-panel').css('display', 'none'); //Hide
    }
}

//Display quick links for desktop 
$(document).ready(function () {
    toggleTableOfContents('min-width:769px');
    links.first().click();
});

//Display panel on mobile/tablet when clicking table of contents link
$('#table-contents').on('click', (e) => {
    toggleTableOfContents('max-width:768px');
    $('ul#tabs-tabVertical').css('display', 'block');
});

//Close panel
$('#close-panel').on('click', () => {
    toggleTableOfContents('min-width:800px');
});

//Quick links and panels
links.on('click', (e) => {
    e.preventDefault();

    //Remove active class from links and panels 
    links.removeClass('active');
    $('div.tab-pane.block').addClass('hidden');
    panels.removeClass('block');

    //Add active class to clicked link and show content
    $(e.target).addClass('active');

    let panelId = $(e.target).attr('href').split('#')[1];
    let panel = document.getElementById(panelId);
    panel.classList.remove('hidden');
    panel.classList += ' block';

    //Hide panel for mobile/tablet
    if (window.matchMedia('(max-width:768px)').matches) {
        toggleTableOfContents('min-width:769px');
    }
});





