import $ from 'cash-dom'

const updateCartCheckoutSavingsMessage = (cartData) => {
  let messageElement = $('#cart-checkout-savings-message')
  let items = cartData.items
  let item
  for (let i = 0, n = items.length; i < n; ++i) {
    item = items[i]

    if (
      item.properties != null &&
      item.properties._pura_checkout_savings_message != null
    ) {
      messageElement.html(item.properties._pura_checkout_savings_message)
      messageElement.removeClass('hidden')
      return
    }
  }

  messageElement.addClass('hidden')
  return
}

export { updateCartCheckoutSavingsMessage }
