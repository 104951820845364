import $ from 'cash-dom'
import { debounce, throttle } from '../utilities/toolkit'

const headers = $('.mobile-header, .desktop-header')

const checkHeaderPosition = () => {
  if (window.pageYOffset > 60) {
    headers.attr('data-scrolling', 'true')
    $('[data-action="device-sticky-cart"]').addClass('hidden')
  } else if (window.pageYOffset < 50) {
    headers.attr('data-scrolling', 'false')
    $('[data-action="device-sticky-cart"]').removeClass('hidden')
  }
}

const stickyAddToCart = () => {
  if ($(window).width() < 767) {
    $('[data-action="sticky-button"]').text('Buy')
  } else {
    $('[data-action="sticky-button"]').text('Add to cart')
  }
}

// Throttle scroll for every 100ms and make passive
window.addEventListener('scroll', throttle(checkHeaderPosition, 50), {
  passive: true,
})

// Make final check when we're done scrolling
// sometimes the throttle function misses it
// if you scroll up too fast
window.addEventListener('scroll', debounce(checkHeaderPosition, 150), {
  passive: true,
})

$(document).ready(() => {
  checkHeaderPosition()
  stickyAddToCart()
})

$(window).on('resize', () => {
  stickyAddToCart()
})
