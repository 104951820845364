import $ from 'cash-dom'
import { getURLParam } from '../utilities/toolkit'

const campaignParam = getURLParam('utm_campaign')

if (campaignParam == 'referral_program' && Pura.template.name == 'index') {
  // Ad yotpo js sdk if coming from a referral link
  ;(function () {
    let script = document.createElement('script')
    script.src =
      'https://cdn-loyalty.yotpo.com/loader/cj8-FOnxwMMjZQdL7pmAVA.js'
    script.type = 'text/javascript'
    script.crossOrigin = 'anonymous'
    document.head.appendChild(script)
  })()
}

document.addEventListener('DOMContentLoaded', function () {
  if (campaignParam == 'referral_program' && Pura.template.name == 'index') {
    let onLoad = true

    function referredCustomerCoupon(code) {
      $('#referral-modal').attr('aria-hidden', false)
      const referralCode = $('[data-element="referral-code"]')

      if (referralCode.length > 0) {
        if (code) {
          $(referralCode).text(code)
          $(referralCode).attr('data-text', code)
        } else {
          // Update eligibility text if user does not meet requirements
          if (onLoad == false) {
            $(referralCode).text(
              'You are not eligible to use this referral code.'
            )
          }
        }
      }
      onLoad = false
    }
    $(document).on(
      'swell:referred:customer:discount',
      function (event, couponCode) {
        const code = event.detail.coupon_code
        localStorage.setItem('swell-referred-customer-coupon-code', code)
        referredCustomerCoupon(code)
      }
    )

    // Display Popup if on homepage and if referal link is active
    $(document).ready(function () {
      const swellCouponCode = localStorage.getItem(
        'swell-referred-customer-coupon-code'
      )
      referredCustomerCoupon(swellCouponCode)
    })
  }
})
