import $ from 'cash-dom'
import { getURLParam } from '../utilities/toolkit'

if (
  Pura.template.suffix === 'opt-out' ||
  Pura.template.suffix === 'do-not-sell-info'
) {
  const submissionState = getURLParam('contact_posted')

  $('form').eq(0).prop('id', 'data-deletion-request')
  $('form').eq(1).prop('id', 'data-copy-report')

  setTimeout(() => {
    if (submissionState === 'true') {
      $('#submission-modal').attr('aria-hidden', 'false')
    }
  }, 500)

  $('#data-deletion-request-submit, #data-copy-report-submit').on(
    'click',
    function (e) {
      let form = $(e.target.closest('form'))
      let type = form.find('#data-compliance-type').val().trim()
      let email = form.find('#data-compliance-email').val().trim()
      let firstName = form.find('#data-compliance-first-name').val().trim()
      let lastName = form.find('#data-compliance-last-name').val().trim()

      let timestamp = new Date()

      analytics.track('Data Compliance Request', {
        'form type': type,
        'email': email,
        'first name': firstName,
        'last name': lastName,
        'timestamp': timestamp,
      })
    }
  )

  $('#data-deletion-request').on('submit', async function (e) {
    e.preventDefault()

    let baseUrl = 'https://trypura.io'
    if (window.location.hostname !== 'pura.com') {
      baseUrl = 'https://staging.trypura.io'
    }

    if (window.Pura && window.Pura.customer) {
      try {
        const confirmDeletion = window.confirm(
          'Are you sure you want to delete your account? This action cannot be undone.'
        )

        if (confirmDeletion) {
          const userToken = Pura.customer.token
          const data = await deleteAccount(baseUrl, userToken)

          if (data) {
            window.location.href = `/account/logout`
          }
        }
      } catch (error) {
        console.error('Error during account deletion:', error)
      }
    }
  })

  async function deleteAccount(baseUrl, token) {
    const url = `${baseUrl}/mobile/api/v2/account`
    const response = await fetch(url, {
      crossDomain: true,
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })

    if (!response.ok) {
      throw new Error('Failed to delete account: ' + response.statusText)
    }

    const data = await response.json()
    return data
  }
}
