import $ from 'cash-dom'
import { submitShogunForm } from '../resources/shogun'

const submitShogun = (data, user, userID, email, form, formType) => {
  submitShogunForm(data).then((res) => {
    if (res.status == 200) {
      if (!Pura.customer && !userID) {
        user.setEmail(email)
      }
      if (formType === 'notify_back_in_stock_product_ids') {
        user.addToCustomAttributeArray(formType, Pura.product.id.toString())
      } else {
        user.setCustomUserAttribute(formType, 'true')
      }

      form.find('.form-header, .input-group, .button').addClass('hidden')
      form.find('.input-group-checkbox').addClass('hidden')
      form.find('.error-message').addClass('hidden')
      form.find('.success-message').removeClass('hidden')
    } else {
      form.find('.error-message').removeClass('hidden')
    }
  })
}

$('#product-notification-form').on('submit', (e) => {
  e.preventDefault()

  if (Pura.product == null || Pura.product.title == null) return
  const form = $(e.target)
  const formNameExt = form.find('#product-notification-subject').val().trim()
  const email = form.find('#product-notification-email').val().trim()
  const formType = 'notify_back_in_stock_product_ids'
  const user = braze.getUser()
  const userID = user.getUserId()

  const data = {
    'site_id': '5ff7a6a1-dcbb-430f-99fa-5e7636e2e3a7',
    'form_submission_email': 'hTdW78qNwYPnGNuIJIkMBQ==\n',
    'form_name': `${Pura.product.title} ${formNameExt}`,
    'fields': [
      {
        'label': 'email',
        'value': email,
        'type': 'email',
      },
    ],
  }
  submitShogun(data, user, userID, email, form, formType)
})

$('#email-capture-form').on('submit', (e) => {
  e.preventDefault()

  const form = $(e.target)
  const email = form.find('#product-notification-email').val().trim()
  const formName = form.data('name').trim()
  const formType = form.data('type').trim()

  const user = braze.getUser()
  const userID = user.getUserId()

  const data = {
    'site_id': '5ff7a6a1-dcbb-430f-99fa-5e7636e2e3a7',
    'form_submission_email': 'hTdW78qNwYPnGNuIJIkMBQ==\n',
    'form_name': formName,
    'fields': [
      {
        'label': 'email',
        'value': email,
        'type': 'email',
      },
    ],
  }
  submitShogun(data, user, userID, email, form, formType)
})
