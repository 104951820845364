import $ from 'cash-dom'

if (Pura.template.name == 'search') {
  const updateSearchResults = () => {
    const resultsFound = $('output .totalCount').text() || '0'
    const queryTerm =
      decodeURIComponent(location.search.slice(3).replace(/\+/g, ' ')) || false
    const updatedMetaTitle = `Search: ${resultsFound} results found ${
      queryTerm ? `for "${queryTerm}"` : ''
    }`

    document.title = updatedMetaTitle
    $("meta[property='og:title']").attr('content', updatedMetaTitle)
    $("meta[name='twitter:title']").attr('content', updatedMetaTitle)
  }

  $(window).on('reactAppLoaded', updateSearchResults)
}
