import $ from 'cash-dom'
import { setTags, clearFilters, setFilterCount } from '../pages/collection'

const filterPanel = document.querySelector('[data-element="filter-panel"]')

const showFilterPanel = () => {
  filterPanel.setAttribute('aria-expanded', 'true')
}

const hideFilterPanel = () => {
  filterPanel.setAttribute('aria-expanded', 'false')
}

const buildTagArray = () => {
  let checkedItems = $('[data-element="filter-item"] input:checked')
  let tagArray = []

  for (let i = 0; i < checkedItems.length; ++i) {
    tagArray.push(checkedItems.eq(i).attr('data-tag'))
  }

  setTags(tagArray.join('+'))
  setFilterCount(tagArray.length)
}
if (filterPanel != null) {
  // toggle mobile filter panel
  document.addEventListener('click', (e) => {
    if (!e.target.closest('[data-action="toggle-filter-panel"]')) return
    e.preventDefault()
    if (filterPanel.getAttribute('aria-expanded') == 'false') {
      showFilterPanel()
      return
    }
    hideFilterPanel()
  })

  // clear filters
  document.addEventListener('click', (e) => {
    if (!e.target.closest('[data-action="clear-filters"]')) return
    e.preventDefault()
    clearFilters()
    hideFilterPanel()
  })

  // toggle collection filters
  filterPanel.addEventListener('click', (e) => {
    if (!e.target.closest('[data-action="toggle-filter-item"]')) return
    e.stopPropagation()

    const filterItem = e.target.closest('[data-element="filter-item"]')
    let filterInput = $(filterItem.querySelector('[type="checkbox"]'))

    // make sure only one brand is selected
    if (
      filterInput.attr('data-tag').includes('brand-') &&
      filterInput.prop('checked')
    ) {
      let checked = filterInput.prop('checked')
      // clear other selected brands
      $('#filter-brands-list input:checked').prop('checked', false)
      // select this brand
      filterInput.prop('checked', checked)
    }

    buildTagArray()
  })

  filterPanel.addEventListener('click', (e) => {
    if (!e.target.closest('[data-action="show-more-brands"]')) return

    let brandList = $('#filter-brands-list')
    const showBrandsButton = $('[data-action="show-more-brands"]')

    if (brandList.attr('aria-expanded') == 'false') {
      brandList.attr('aria-expanded', 'true')
      showBrandsButton.addClass('hidden')
    } else {
      brandList.attr('aria-expanded', 'false')
      showBrandsButton.removeClass('hidden')
    }
  })

  // Sort Order of Strength Filters
  if ($('#strength-list li').length === 3) {
    $('#strength-list li').last().prependTo('#strength-list')
  }

  if (filterPanel) {
    filterPanel
      .querySelectorAll('[data-component="expandable-content"]')
      .forEach((category) => {
        if (!category.querySelector('ul > li')) {
          category.style.display = 'none'
        }
      })
  }
}
export { hideFilterPanel, showFilterPanel }
