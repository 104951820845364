import { updateCartAttributes } from '../resources/shopify-cart'




const checkCartForBYGS = (cart) => {

	// exit if cart does not contain attributes
	if ( !cart.attributes.recipient_first_name ) return;

	let items = cart.items
	let containsBYGS = false;

	// is there a BYGS in the cart?
	for (let i=0, n=items.length; i < n; ++i){
		if (items[i].product_type == "Gift"){
			containsBYGS = true
			break
		}	
	}

	// if there isn't one but the attributs exist
	if (!containsBYGS) {
		// clear the attributes
		clearBYGSAttributesFromCart()
	}


}


const clearBYGSAttributesFromCart = () => {
	const clearBYGSAddres = {
		"sender_first_name": null,
		"sender_last_name": null,
		"sender_email": null,
		"recipient_first_name": null,
		"recipient_last_name": null,
		"recipient_email": null,
		"street_address_1": null,
		"city": null,
		"state": null,
		"zip": null
	}

	updateCartAttributes(clearBYGSAddres)
		.then( res => {
			console.log('removed attributes from cart', res)
		})
}




export { checkCartForBYGS }