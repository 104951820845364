import $ from 'cash-dom'

if (Pura.template.suffix === 'gift-sub-landing-page') {
  const button = $('#gifting-continue')

  $(document).ready(function () {
    setTimeout(() => {
      const selectedOption = $('input[name="product_options"]:checked').val()
      if (selectedOption) {
        button.removeAttr('disabled')
      }
    }, 500)
  })

  $('.gifting-sub-selection').change(function (e) {
    button.removeAttr('disabled')
  })

  $('#gifting-continue').on('click', function () {
    const selectedOption = $('input[name="product_options"]:checked').val()
    button.removeAttr('disabled')

    if (selectedOption) {
      window.location.href = selectedOption
    }
  })
}

$('[data-action="back-button"]').click(function () {
  if (history.length > 1) {
    history.back()
  } else {
    window.location.href = '/pages/gifting'
  }
})
