import $ from 'cash-dom'

document.addEventListener('click', function (e) {
  if (!e.target.closest('[data-action="show-modal"]')) return
})

// document.addEventListener('click', function(e) {
// 	console.log('click', e )
// 	if (!e.target.closest('[data-action="hide-modal"]')) return;
// 	console.log('hide modal', e.target)
// 	e.target.closest('modal-panel').hide()
// })
