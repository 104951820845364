import $ from 'cash-dom'

let countdownInterval

const startCountdown = (element, layoutType = 'default') => {
  const countdownElement = element

  let endTime = new Date(countdownElement.getAttribute('data-time'))
  const now = Math.floor(Date.now() / 1000)
  const offset = endTime.getTimezoneOffset() - 420
  endTime.setTime(endTime.getTime() - offset * 60 * 1000)
  endTime = Date.parse(endTime) / 1000

  const timeLeft = endTime - now
  if (timeLeft <= 0) return

  const updateCountdownDisplay = () => {
    const currentTime = Math.floor(Date.now() / 1000)
    const remainingTime = endTime - currentTime

    if (remainingTime <= 0) {
      clearInterval(countdownInterval)
      countdownElement.classList.add('hidden')
    } else {
      const days = Math.floor(remainingTime / 86400)
      const hours = Math.floor((remainingTime % 86400) / 3600)
      const minutes = Math.floor((remainingTime % 3600) / 60)
      const seconds = Math.floor(remainingTime % 60)

      if (layoutType === 'inline') {
        countdownElement.textContent = `${days}d : ${hours}h : ${minutes}m : ${seconds}s`
      } else if (layoutType === 'stacked') {
        countdownElement.querySelector('[data-time-unit="days"]').textContent =
          days.toString().padStart(2, '0')
        countdownElement.querySelector('[data-time-unit="hours"]').textContent =
          hours.toString().padStart(2, '0')
        countdownElement.querySelector(
          '[data-time-unit="minutes"]'
        ).textContent = minutes.toString().padStart(2, '0')
        countdownElement.querySelector(
          '[data-time-unit="seconds"]'
        ).textContent = seconds.toString().padStart(2, '0')
      }
    }
  }

  updateCountdownDisplay()

  countdownInterval = setInterval(updateCountdownDisplay, 1000)
}

const endCountdown = () => {
  clearInterval(countdownInterval)
}

const $sectionCountdown = $('[data-element="section-countdown"]').get(0)
if ($sectionCountdown) {
  startCountdown($sectionCountdown, 'stacked')
}

export { startCountdown, endCountdown }
