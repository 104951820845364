const currencyFormat = window.Pura.currency.format

const renderCartItem = (item) => {
  // title
  let title = item.title
  if (item.selling_plan_allocation != null) {
    title = item.title + ' Subscription'
  }

  // price
  let price
  if (item.discounted_price == 0) {
    price = 'FREE'
  } else {
    price = (item.discounted_price / 100).toFixed(2)
    price = currencyFormat.replace('{{amount}}', price)
    price = price.replace('{{amount_with_comma_separator}}', price)
    price = price.replace('.00', '')
  }

  // compare price
  let comparePrice
  if (item.selling_plan_allocation != null) {
    comparePrice = (
      item.selling_plan_allocation.compare_at_price / 100
    ).toFixed(2)
    comparePrice = currencyFormat
      .replace('{{amount}}', comparePrice)
      .replace('.00', '')
  }

  // check to see if product is dynamically discounted in cart
  if (!comparePrice && item.original_price > item.discounted_price) {
    comparePrice = (item.original_price / 100).toFixed(2)
    comparePrice = currencyFormat
      .replace('{{amount}}', comparePrice)
      .replace('.00', '')
  }

  // shipping frequency message
  let shippingFrequency
  if (item.selling_plan_allocation?.selling_plan) {
    shippingFrequency = item.selling_plan_allocation.selling_plan.name
  }

  // hide quantity
  let hideQuantity = item.properties?._pura_hide_quantity || null
  const hideRemove = item.properties?._pura_hide_remove || null

  if (Pura.giftCard_id === item.product_id) hideQuantity = true
  return cartItemTemplate(
    item,
    title,
    price,
    comparePrice,
    shippingFrequency,
    hideQuantity,
    hideRemove
  )
}

const cartItemTemplate = (
  product,
  title,
  price,
  comparePrice,
  shippingFrequency,
  hideQuantity,
  hideRemove
) =>
  `<div class="cart-item group
    flex py-4 hover:bg-cream transition-medium px-gutter
      overflow-hidden border-b border-neutral-300"
      data-component="cart-item"
      data-variant-id="${product.variant_id}"
      data-key="${product.key}" >
      ${
        hideQuantity === null
          ? `
          <a href="${product.url}" class="block relative">
            <img
              src="${product.image
                ?.replace('.jpg', '_200x.jpg')
                ?.replace('.png', '_200x.png')
                ?.replace('.webp', '_200x.webp')}"
              alt="${title}"
              loading="lazy"
              fetchpriority="low"
              class="w-[100px] h-[126px] object-contain"
            />
          </a>`
          : `
          <div class="block relative">
            <img src="${product.image
              ?.replace('.jpg', '_200x.jpg')
              ?.replace('.png', '_200x.png')
              ?.replace('.webp', '_200x.webp')}"
              alt="${title}"
              loading="lazy"
              fetchpriority="low"
              class="w-[100px] h-[126px] object-contain"  />
          </div>`
      }
      <div class="flex-1 flex flex-col items-start
        ${hideQuantity != null ? `justify-center` : ``}
        ml-10">

        ${
          product.properties?._pura_cart_is_final_sale != null
            ? `<span class="badge-small relative m-0 mb-2 border fw-4">Final sale</span>`
            : ``
        }

        <p class="text-caption-semibold mb-1 product-title">${title}</p>
        ${
          product.variant_title != null &&
          product.product_type == 'Car Fragrance'
            ? `<p class="text-caption opacity-60 mb-1 variant-title">${product.variant_title} Fragrance Life </p>`
            : ``
        }
        ${
          product.type == 'Sample'
            ? `<p class="sample-message text-caption text-neutral-700 mb-1 opacity-60">Scent Cards</p>`
            : ``
        }
        ${
          shippingFrequency != null
            ? `<p class="shipping-message text-caption text-neutral-700 mb-1 opacity-60 subscription-title">${shippingFrequency}</p>`
            : ``
        }

        <p class="text-caption mb-1 opacity-60">
          ${
            comparePrice
              ? `<span class='line-through fs-12 opacity-50 mr-1'>${comparePrice}</span>`
              : ''
          }
          ${price}
        </p>

				${
          product.properties?._pura_cart_message != null
            ? `<p class="cart-message text-caption opacity-60 mb-2">${product.properties._pura_cart_message}</p>`
            : ``
        }

        <div class="flex items-center justify-between w-100 mt-2">
        ${
          hideQuantity === null
            ? `<div data-component="quantity" class="rounded border border-neutral-300 fs-14 w-[77px]" style="min-height: 26px">
                <button ${product.quantity === 1 ? `disabled class="text-smoke" ` : ''}aria-label="decrement quantity"
                  data-action="decrement">
                  <svg class="svg-decrement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><title>decrement</title><path fill="currentColor" d="M368 224H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h352c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path></svg>
                </button>
                  <input type="number" name="quantity" value="${product.quantity}" min="1" data-element="quantity-input" class="fs-12">
                <button aria-label="increment quantity"
                  data-action="increment">
                  <svg class="svg-increment" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><title>increment</title><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path></svg>
                </button>
               </div>`
            : ``
        }
         ${
           hideRemove === null
             ? `<button
            aria-label="remove item from cart"
            class="button-underline-sm fs-12"
            data-action="remove-item">
              Remove
          </button>`
             : ``
         }
      </div>
      </div>
    </div>`

export { renderCartItem }
