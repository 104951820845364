import $ from "cash-dom";
import { addSlick } from "../packages/slick-mod";

/*
Added to make slick slider work with cash-dom instead of jQuery
*/

var events =
	"blur focus focusin focusout resize scroll click dblclick mousedown mouseup mousemove mouseover mouseout mouseenter mouseleave change select submit keydown keypress keyup contextmenu".split(
		" "
	);

events.forEach((event) => {
	$.fn[event] = function (callback) {
		if (callback) {
			return this.on(event, callback);
		} else {
			return this.trigger(event);
		}
	};
});

$.type = function (obj) {
	if (Array.isArray(obj)) {
		return "array";
	}
	return typeof obj;
};

$.proxy = function (fn, context) {
	var tmp, args, proxy;

	if (typeof context === "string") {
		tmp = fn[context];
		context = fn;
		fn = tmp;
	}

	// Quick check to determine if target is callable, in the spec
	// this throws a TypeError, but we will just return undefined.
	if (typeof fn !== "function") {
		return undefined;
	}

	// Simulated bind
	args = Array.prototype.slice.call(arguments, 2);
	proxy = function () {
		return fn.apply(
			context || this,
			args.concat(Array.prototype.slice.call(arguments))
		);
	};

	// Set the guid of unique handler to the same of original handler, so it can be removed
	proxy.guid = fn.guid = fn.guid || $.guid++;

	return proxy;
};

$.fn.extend({
	exists: function () {
		return this.length > 0;
	},
	vanilla: function () {
		if (this.length === 1) {
			return this.get(0);
		} else if (this.length > 1) {
			return this.get();
		} else {
			return null;
		}
	},
	// DO NOT ACTUALLY USE .end()! cash has no selection hierarchy, this is just used in modded slick slider
	// and is always used at end of chain, so return this, make slick happy and move along
	end: function () {
		return this;
	},
});

addSlick($);

window.$$ = $;

export default $;
