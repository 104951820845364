import $ from 'cash-dom'
import { getCollectionProductPricing } from './sitewide-discount'

const formatPrice = (price) => `$${price.toFixed(2)}`.replace('.00', '')

const loadImages = (images, selectionSlider, selectionPanel, title) => {
  selectionSlider.slick('slickRemove')

  images.forEach((obj, i) => {
    selectionSlider.slick(
      'slickAdd',
      ` <div class="leading-0">
	        <img alt="${title?.toLowerCase() + ' view ' + (i + 1)}" src="${obj}"
	          class="h-[100vw] w-full md:h-full object-cover" >
	      </div>`
    )
  })

  setTimeout(() => {
    selectionPanel[0].show()
  }, 100)
}

const setSelectionPanelToCar = (homeBadge, carBadge) => {
  homeBadge.hide()
  carBadge.show()
}

const setSelectionPanelToHome = (homeBadge, carBadge) => {
  homeBadge.show()
  carBadge.hide()
}

const setSelectionPanelToLegacy = (selectionPanel) => {
  selectionPanel.find('.smart-vial-messaging').hide()
}

const setSelectionPanelToSmartvial = (selectionPanel) => {
  selectionPanel.find('.smart-vial-messaging').show()
}

const updateBYSPanelPrice = (productData, comparePriceEle, priceEle) => {
  let { price, comparePrice } = productData
  let bysProductType = Pura.product.type || false
  comparePrice = comparePrice / 100

  const purchaseType = $(
    '#BYS-selection-panel [name="purchase-type"]:checked'
  ).val()

  if (price <= 0 || price == '$0.00') {
    if (purchaseType === 'SUB') {
      comparePrice *= 0.8
    }
    comparePriceEle.html(formatPrice(comparePrice)).show()
    priceEle.html('FREE')
    return
  }

  if (purchaseType === 'SUB') {
    price *= 0.8
  }

  price = getCollectionProductPricing(price, bysProductType)

  price = Math.round(price) / 100

  priceEle.html(formatPrice(price))

  if (comparePrice > price) {
    comparePriceEle.html(formatPrice(comparePrice)).show()
  } else {
    comparePriceEle.hide()
  }
}

export {
  loadImages,
  setSelectionPanelToCar,
  setSelectionPanelToHome,
  setSelectionPanelToLegacy,
  setSelectionPanelToSmartvial,
  updateBYSPanelPrice,
}
