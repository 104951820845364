import $ from '../utilities/cash-shim'

/*
https://stackoverflow.com/questions/1086404/string-to-object-in-js
Convert string to proper json object
var jsonStr = strObj.replace(/(\w+:)|(\w+ :)/g, function(s) {
  return '"' + s.substring(0, s.length-1) + '":';
});
*/

$('[data-component="slider"]').each(function (i, obj) {
  const sliderComponent = $(obj)
  const slider = sliderComponent.find('[data-element="slides"]')
  const prevArrow = obj.querySelector('[data-action="previous"]')
  const nextArrow = obj.querySelector('[data-action="next"]')
  let options = sliderComponent.attr('data-options')

  options = JSON.parse(options)

  if (options == null) {
    options = {
      infinite: true,
      speed: 800,
      rows: 0,
      cssEase: 'ease',
    }
  }

  options.prevArrow = prevArrow
  options.nextArrow = nextArrow

  let newSlider = slider.slick(options)

  newSlider.on('afterChange', function (event, slick, currentSlide) {
    // console.log('afterChange slick', slick);
  })

  newSlider.on(
    'beforeChange',
    function (event, slick, currentSlide, nextSlide) {
      // console.log('beforeChange slick', slick )
    }
  )
})

// Delay Recommended Products Slider to init after liquid
setTimeout(function () {
  $('[data-component="slider_recomended"]').each(function (i, obj) {
    const sliderComponent = $(obj)
    const slider = sliderComponent.find('[data-element="slides"]')
    const prevArrow = obj.querySelector('[data-action="previous"]')
    const nextArrow = obj.querySelector('[data-action="next"]')
    let options = sliderComponent.attr('data-options')
    $(this).removeClass('hidden')

    options = JSON.parse(options)

    if (options == null) {
      options = {
        infinite: true,
        speed: 800,
        rows: 0,
        cssEase: 'ease',
      }
    }

    options.prevArrow = prevArrow
    options.nextArrow = nextArrow

    let newSlider = slider.slick(options)

    newSlider.on('afterChange', function (event, slick, currentSlide) {
      // console.log('afterChange slick', slick);
    })

    newSlider.on(
      'beforeChange',
      function (event, slick, currentSlide, nextSlide) {
        // console.log('beforeChange slick', slick )
      }
    )
  })
}, 2000)

// Initialize gallerySlider for the main product gallery
const gallerySlider = $('#product-gallery-slider').slick('getSlick')
let debounceTimer

// PDP Slider Thumbnail on hover with debounce
$('#product-thumbnail-slider').on('mouseover', '.slick-slide', function (e) {
  clearTimeout(debounceTimer)

  debounceTimer = setTimeout(() => {
    let $currTarget = $(e.currentTarget)
    let index = $currTarget.data('slick-index')

    if (gallerySlider) gallerySlider.slickGoTo(index)
  }, 100)
})

// Handle variant-specific sliders with debounce
const thumbnailSliders = $('[data-component="variant-thumbnail-sliders"]')

thumbnailSliders.each(function () {
  const $thisSlider = $(this)

  // Mouseover for each thumbnail slide
  $thisSlider.on('mouseover', '.slick-slide', function (e) {
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => {
      let $currTarget = $(e.currentTarget)
      let index = $currTarget.index()

      const variantId = $thisSlider.attr('class').match(/thumb-slider-(\d+)/)[1]
      const mainSlider = $(`.slider-${variantId}`).slick('getSlick')

      if (mainSlider) {
        mainSlider.slickGoTo(index)
      }
    }, 100)
  })
})

const reInitSliders = (slider) => {
  const $sliderContainer = $(slider)
  const $gallerySlider = $sliderContainer.find('[data-element="slides"]')

  const dataOptions = $sliderContainer
    .attr('data-options')
    .trim()
    .replace(/(\r\n|\n|\r)/gm, '')

  const options = JSON.parse(dataOptions.replace(/&quot;/g, '"'))

  $gallerySlider.not('.slick-initialized').slick({
    slidesToShow: options.slidesToShow || 1,
    slidesToScroll: options.slidesToScroll || 1,
    speed: options.speed || 300,
    arrows: options.arrows !== undefined ? options.arrows : true,
    asNavFor: options.asNavFor || null,
    nextArrow: $sliderContainer.find('[data-action="next"]'),
    prevArrow: $sliderContainer.find('[data-action="previous"]'),
  })

  if (options.asNavFor) {
    const $thumbnailSlider = $(options.asNavFor)

    if ($thumbnailSlider.length) {
      $thumbnailSlider.not('.slick-initialized').slick({
        slidesToShow: options.thumbnailSlidesToShow || 5,
        slidesToScroll: options.thumbnailSlidesToScroll || 1,
        speed: options.thumbnailSpeed || 300,
        focusOnSelect: true,
        infinite: true,
        centerMode: false,
        asNavFor: $gallerySlider,
      })
    }
  }
}
export { reInitSliders }
