const cartUpsellItem = (product, price, isDiscounted) =>
  `<div class="bg-cream hover:bg-blue-100 flex py-4 transition-medium px-gutter overflow-hidden border-t border-neutral-300"
      data-component="cart-item"
      data-test-tag="${product.id}"
      data-test-type="${product.type}"
      >
     <a href="/products/${product.handle}" class="block relative">
              <img src="${product.image}"
                alt="${product.title}"
                width="100"
                height="126"
                loading="lazy"
                fetchpriority="low"
                class="w-[100px] h-[126px] object-cover"/>
            </a>
        <div class="flex-1 flex flex-col items-start justify-center px-10 bg-white">
        <p class="text-caption-semibold mb-1" >${product.title}</p>

        ${
          isDiscounted || product.isDiscounted === 'true'
            ? `<p class="text-caption mb-1 opacity-60">
                 <span class="line-through fs-12 opacity-50 mr-1">${
                   product.compare_at_price || product.price
                 }</span>$${price}
               </p>`
            : `<p class="text-caption mb-2 opacity-60">$${price}</p>`
        }
        <div class="flex flex-row w-100 justify-between">
        <div data-component="quantity" class="rounded bg-cream group-hover:bg-white transition-fast fs-14 w-[77px]" style="min-height: 26px;">
           <button disabled class="text-smoke" aria-label="decrement quantity"
             data-action="decrement">
             <svg class="svg-decrement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><title>decrement</title><path fill="currentColor" d="M368 224H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h352c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path></svg>
           </button>
           <input type="number" name="quantity" value="1" min="1"
             data-element="quantity-input" class="fs-12">
           <button aria-label="increment quantity"
             data-action="increment">
             <svg class="svg-increment" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><title>increment</title><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path></svg>
           </button>
         </div>
         <button aria-label="add item to cart"
           data-test-tag="${product.title}"
           data-test-type="${product.type}"
           data-add-variant-to-cart="${product.variant}"
            ${
              product.isDiscounted === 'true'
                ? `data-properties="_pura_cart_is_discounted"`
                : ``
            }
           data-properties-pura-full-price="${product.price}"
           class="button-underline-sm fs-12 add-cart-upsell">
           Add
         </button>
       </div>
      </div>
    </div>`

export { cartUpsellItem }
