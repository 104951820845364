import $ from 'cash-dom'

const mobileMenuStack = ['root']

// Segue forward to selected menu panel
$('[data-action="segue-to-mobile-nav"]').on('click', (e) => {
  const childHandle = $(e.currentTarget).data('nav-child')
  const thisMenu = $(e.currentTarget).closest('.mobile-nav-list')
  const nextMenu = $(`[data-nav-handle="${childHandle}"]`)

  thisMenu.addClass('position-left')
  nextMenu.removeClass('position-right')
  nextMenu.find('[data-focus="start"]').eq(0).focus()

  mobileMenuStack.push(childHandle)
})

// Segue backwards to previous menu panel
$('[data-action="segue-back"]').on('click', () => {
  const currentMenu = mobileMenuStack.pop()
  $(`[data-nav-handle="${currentMenu}"]`).addClass('position-right')

  const previousMenuHandle = mobileMenuStack[mobileMenuStack.length - 1]
  const prevMenu = $(`[data-nav-handle="${previousMenuHandle}"]`)
  prevMenu.removeClass('position-left')
  prevMenu.find('[data-focus="start"]').eq(0).focus()
})
