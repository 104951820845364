import {
  categoryMerchandising,
  KlevuFetch,
  kmcRecommendation,
  sendRecommendationViewEvent,
} from '@klevu/core'
import { initializeKlevu } from './klevu-init'


const getCollection = async (collectionId) => {
  initializeKlevu()

  const products = await KlevuFetch(
    categoryMerchandising(collectionId, {
      groupCondition: {
        groupOperator: 'ANY_OF',
        conditions: [
          {
            'key': 'tags',
            'valueOperator': 'EXCLUDE',
            'singleSelect': false,
            'values': ['type_Legacy', 'display_Search'],
          },
        ],
      },
      id: 'collection',
    })
  )
  return products
}

const getPersonalizations = async (personalizedRecs) => {
  initializeKlevu()

  const products = await KlevuFetch(
    kmcRecommendation(
      Pura.environment.production == 'true'
        ? 'k-5d8ebf5f-d664-4738-b339-a3671c8cc36f'
        : 'k-bdadd86b-4887-44ac-b4f2-1d1101040ab8',
      {
        id: personalizedRecs,
      },
      sendRecommendationViewEvent('Personal Recommendations')
    )
  )
  return products
}

const getProductRecommendations = async (productRecs) => {
  initializeKlevu()

  const products = await KlevuFetch(
    kmcRecommendation(
      Pura.environment.production == 'true'
        ? 'k-fe4fc7d2-a5d8-415f-889a-4eee495c8e7e'
        : 'k-a39a715c-2c7a-414a-9064-a9e7212ee86c',
      {
        id: productRecs,
        currentProductId: Pura.product.variants[0].id,
        itemGroupId: Pura.product.id,
      },
      sendRecommendationViewEvent('Product Recommendations')
    )
  )
  return products
}

export { getCollection, getPersonalizations, getProductRecommendations }
