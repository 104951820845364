import $ from 'cash-dom'

const buildActiveCampaigns = (activeCampaigns) => {
  let id
  let details
  activeCampaigns.forEach((campaign) => {
    id = campaign.id
    details = campaign.details
    if (campaign.customerCompleted === true) {
      $(`[data-campaign-id='${id}']`)
        .find('[data-action="rewards-hover-info"]')
        .text("You've completed this reward!")
      $(`[data-campaign-id='${id}']`).attr('data-campaign-id', '')
    } else {
      $(`[data-campaign-id='${id}']`)
        .find('[data-action="rewards-hover-info"]')
        .text(details)
    }
  })
}

export { buildActiveCampaigns }
