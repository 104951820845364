import { getCookie, setCookie } from '../utilities/cookies'
import { pushToDataLayer } from '.'

const platformIdentification = (sessionId) => {
  const platform = Pura.isInApp ? 'app' : 'web'

  pushToDataLayer('platform_identification', { platform })
  setCookie('platformIdentification', sessionId, 14)
}

// wait for shopify cart cookie - if new then resend platformID
window.addEventListener('load', () => {
  const setPlatformId = getCookie('platformIdentification')
  const sessionId = getCookie('cart')

  if (!setPlatformId || (setPlatformId && setPlatformId !== sessionId)) {
    platformIdentification(sessionId)
  }
})
