const getGiftBag = (items) => {
  for (let i = 0; i < items.length; ++i) {
    if (items[i].sku == '900-02205') {
      return items[i]
    }
  }
  return false
}

const groupHasGiftBag = (items) => !!getGiftBag(items)

const renderGroupGiftBag = (groupID, items) => {
  const item = getGiftBag(items)
  return `
		<div class="flex py-4" >
				<img
					src="${item.image?.replace('.jpg', '_x300.jpg')}"
					alt="${item.title}"
					class="w-[100px] h-[100px] object-cover" />
				<div class="flex-1 flex flex-col items-start justify-center pl-12" >
					<p class="text-caption-semibold mb-[2px]">${item.title}</p>
					<p class="text-caption mb-[2px]">Ships one time</p>
					<div class="flex items-center justify-between w-full pr-6">
						<p class="text-caption mb-0">$${item.price / 100}</p>
						<button
							data-action="remove-gift-bag-from-group"
							data-group-id="${item.properties._pura_group_id}"
							data-key="${item.key}"
							class="button-underline-sm fs-12" >
							Remove
						</button>
					</div>
				</div>
		</div>`
}

const renderGroupGiftBagAddon = (groupID, quantity, packingId) => `
		<div class="bg-cream px-gutter py-4" >
			<p class="text-body-sm mb-2" >Add a gift bag to your set</p>
			<div class="flex" >
				<img
					src="${Pura.cart.gift_bag_image}"
					alt="gift bag"
					class="w-[100px] h-[126px] object-cover" />
				<div class="flex-1 flex flex-col items-start justify-center pl-12 bg-white" >
					<p class="text-caption-semibold mb-[2px]">Gift bag</p>
					<p class="text-caption mb-[2px]">Ships one time</p>
					<div class="flex items-center justify-between w-full pr-6">
						<p class="text-caption mb-0">$${Pura.cart.gift_bag_price / 100}</p>
						<button
							data-action="add-gift-bag-to-group"
							data-group-id="${groupID}"
							data-quantity="${quantity}"
							data-packing-id="${packingId}"
							class="button-underline-sm fs-12" >
							Add gift bag
						</button>
					</div>
				</div>
			</div>
		</div>`

export { renderGroupGiftBag, renderGroupGiftBagAddon, groupHasGiftBag }
