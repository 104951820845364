import $ from 'cash-dom'
import { setSortOption } from '../pages/collection'

const sortMenu = $('[data-component="sort-menu"]')
const sortPanel = $('[data-action="toggle-sort-panel"]')

$('[data-action="sort-collection"').on('click', (e) => {
  e.preventDefault()

  setSortOption(e.target.getAttribute('data-sort-by'))
  hideSortMenu()
})

$(sortPanel).on('click', (e) => {
  e.preventDefault()

  if ($(sortPanel).attr('aria-expanded') === 'false') {
    showSortMenu()
    return
  }
  hideSortMenu()
})

const showSortMenu = () => {
  sortMenu.attr('aria-visible', 'true')
  sortPanel.attr('aria-expanded', 'true')
}

const hideSortMenu = () => {
  sortMenu.attr('aria-visible', 'false')
  sortPanel.attr('aria-expanded', 'false')
}

export { hideSortMenu }
