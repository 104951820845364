import $ from 'cash-dom'
import { debounce } from '../utilities/toolkit'
import {
  clearFilters,
  setDiscountProductPrice,
  setFreeProductPrice,
} from '../pages/collection'
import { searchProducts } from '../resources/shopify-search'

let lastSearch

let searchTemplate = 'BYS-smartvial-SUB'

// dirty hack to override this for BOGO sitewide discount
if (Pura.product && Pura.product.id == 7113540337773) {
  searchTemplate = 'BYS-smartvial-SUB-BOGO'
}

$('[data-action="search-bys"]').on(
  'keyup',
  debounce(function (e) {
    e.preventDefault()

    const query = e.target.value.trim()
    const BYSPanel = $(this).data('bys-panel')

    // exit if the value is the same
    if (query == lastSearch) return
    lastSearch = query

    // exit if empty
    if (query.length == 0) {
      // Reset Collection
      clearFilters()
      return
    }

    // clear filters because they don't work on search
    clearFilters(false)

    // ge products with search template
    searchProducts(query, searchTemplate).then((res) => {
      $('#collection-pagination-button').addClass('hidden')

      // No products found
      if (res.trim().length == 0) {
        $('#collection-products').html('<p>No results found</p>')
        return
      }

      $('#collection-products').html(res)
      if (Pura.BYS.panel_2_fragrances_are_free && BYSPanel == 2) {
        setFreeProductPrice()
      } else if (Pura.cart.enable_sitewide_discount) {
        setDiscountProductPrice()
      }
    })
  }, 350)
)

$('[data-action="toggle-bys-mobile-search"]').on('click', function (e) {
  let searchPanel = $('#byb-mobile-search-panel')
  if (searchPanel.css('display') == 'none') {
    searchPanel.show()
    $('#byb-mobile-search-field').focus()
  } else {
    searchPanel.hide()
  }
})

const setBYSSearchTemplate = (templateName) => {
  switch (templateName) {
    case 'SUB Smart Vial':
      searchTemplate = 'BYS-smartvial-SUB'
      break
    case 'SUB Smart Vial (ignore sitewide discount)':
      searchTemplate = 'BYS-smartvial-SUB-BOGO'
      break
    case 'OTP Smart Vial':
      searchTemplate = 'BYS-smartvial-OTP'
      break
    case 'OTP Legacy':
      searchTemplate = 'BYS-legacy-OTP'
      break
    case 'Car OTP':
      searchTemplate = 'BYS-car-OTP'
      break
    case 'Car SUB':
      searchTemplate = 'BYS-car-SUB'
      break
    case 'Car SUB (ignore sitewide discount)':
      searchTemplate = 'BYS-car-ignore-discounts'
      break
    default:
  }

  if (
    Pura.product.id == 7113540337773 &&
    searchTemplate == 'BYS-smartvial-SUB'
  ) {
    searchTemplate = 'BYS-smartvial-SUB-BOGO'
  }
}

export { setBYSSearchTemplate }
