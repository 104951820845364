import $ from 'cash-dom'
import {
  displayCustomerPoints,
  displayReferralData,
} from './yotpo/customer-points'
import { onErrorRedeem, onSuccessRedeem } from './yotpo/code-redemption'
import { buildRewardOptions } from './yotpo/reward-options'
import { buildActiveCampaigns } from './yotpo/campaigns'

if (Pura.template.suffix == 'rewards-new' || Pura.template.suffix == 'refer') {
  document.addEventListener('DOMContentLoaded', function () {
    $(document).on('swell:setup', function () {
      const customerDetails = swellAPI.getCustomerDetails()
      displayCustomerPoints(customerDetails)
      displayReferralData(customerDetails)

      const activeCampaigns = swellAPI.getActiveCampaigns()
      buildActiveCampaigns(activeCampaigns)

      const rewardOptions = swellAPI.getActiveRedemptionOptions()
      buildRewardOptions(customerDetails, rewardOptions)

      $('select#redemption-options').on('change', function () {
        $('#existing-subscription')
          .find('[data-action="option-id-redemption"]')
          .attr('data-redemption-option-id', this.value)
      })

      $('[data-action="option-id-redemption"]').on('click', function () {
        let optionValue = $(this).attr('data-redemption-option-id')
        let pointsBalance = customerDetails.pointsBalance
        let optionValueSplit = optionValue.split('+')
        let optionID = optionValueSplit[0]
        let optionCost = optionValueSplit[1]
        let optionType = optionValueSplit[2].trim()

        if (
          pointsBalance >= optionCost &&
          optionType == 'recharge_discount_fixed_amount'
        ) {
          swellAPI.makeRedemption(
            { redemptionOptionId: optionID },
            onSuccessRedeem,
            onErrorRedeem
          )
        } else {
          $('.redeem-error')
            .text('You do not have enough points to redeem this offer')
            .addClass('mt-4')
        }
      })

      $('#go-back-modal').on('click', function () {
        $('#no-existing-sub-modal').attr({
          'data-state': 'hidden',
          'aria-hidden': 'true',
        })
      })
    })
  })
}

if (Pura.template.name == 'product') {
  $('[data-action="open-reviews"]').on('click', () => {
    $('.yotpo-nav-content').css('display', 'block')
    $('.new-yotpo-small-box').css('display', 'block')
    $('[data-action="open-reviews"]').addClass('hidden')
  })
}
