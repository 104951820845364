import $ from 'cash-dom'
import { cart } from './cart'
import {
  hasCarDevice,
  hasCarFragrance,
  hasCarSet,
  hasSamples,
} from './cart-toolkit'
import { cartHasVariant } from '../utilities/toolkit'
import { addVariantToCart, getCart } from '../resources/shopify-cart'

const cartSamplesComponent = $('[data-element="samples-item-panel"]')
const slideItem = document.querySelector('[data-action="sample-items"]')

const buildSampleProductList = (cart, products) => {
  let list = []

  for (let i = 0; i < products.length; ++i) {
    let item = products[i]
    let variantId = item.variant

    if (!cartHasVariant(cart, variantId)) {
      list.push(products[i])
    }
  }
  return list
}

const renderCartSamples = (items, samplesInCart, samplesType) => {
  let limit_samples

  if (samplesType == 'default') {
    limit_samples = Pura.cart.limit_samples

    $('[data-element="samples-title"]').text(Pura.cart.samples_title)
    $('[data-element="scent-info-panel"]').text(Pura.cart.samples_info_panel)
  } else {
    limit_samples = Pura.cart.car_limit_samples

    $('[data-element="samples-title"]').text(Pura.cart.car_samples_title)
    $('[data-element="scent-info-panel"]').text(
      Pura.cart.car_samples_info_panel
    )
  }

  if (items.length == 0 || samplesInCart >= limit_samples) {
    hideCartSamples()
    return
  } else {
    showCartSamples()
  }
  $('[data-action="sample-items"]').slick('removeSlide', null, null, true)

  for (let i = 0; i < items.length; ++i) {
    let item = items[i]
    $('[data-action="sample-items"]').slick(
      'slickAdd',
      `<div class="cursor-default">
        <div class="mx-4 flex flex-col justify-center items-center">
            <p class="text-caption-semibold text-center cursor-default max-w-[100px]">${item.title}</p>
            <div class="slick-samples cursor-default">
              <img
                alt="${item.title}"
                loading="lazy"
                src="${item.image}"
                width="127"
                height="127"
                />
            </div>
           <button aria-label="add item to cart"
                data-test-tag="${item.title}"
                data-test-type="${item.type}"
                data-element="samples_add_to_cart"
                data-variant="${item.variant}"
                class="text-caption button-underline-sm mt-2">
                  Add Sample
              </button>
            </div>
          </div>
        </div>`
    )
  }
  if (items.length === 1) {
    $('[data-action="sample-items"]').find('.slick-track').addClass('inline')
    $('[data-action="sample-items"]').find('.slick-track').removeClass('flex')
  } else {
    $('[data-action="sample-items"]').find('.slick-track').removeClass('inline')
    $('[data-action="sample-items"]').find('.slick-track').addClass('flex')
  }
}

const showScentSamples = (cart) => {
  let list
  let sampleItems

  let carDevice = hasCarDevice(cart)
  let carFragrance = hasCarFragrance(cart)
  let carSet = hasCarSet(cart)
  let samplesInCart = hasSamples(cart)
  let samplesType

  // Don't show if it's not enabled
  if (!Pura.cart.samples_enabled && !Pura.cart.car_samples_enabled) {
    hideCartSamples()
    return
  }

  if (!sampleItems) {
    if (Pura.cart.samples_enabled) {
      samplesType = 'default'
      sampleItems = Pura.cart.sample_items.sample_items
    } else {
      sampleItems = null
      hideCartSamples()
    }

    if (carDevice || carSet || carFragrance) {
      if (Pura.cart.car_samples_enabled) {
        samplesType = 'car'
        sampleItems = Pura.cart.car_sample_items.sample_items
      } else {
        sampleItems = null
        hideCartSamples()
      }
    }
  }
  if (sampleItems) {
    list = buildSampleProductList(cart, sampleItems)
    renderCartSamples(list, samplesInCart, samplesType)
  }
}

const hideCartSamples = () => {
  cartSamplesComponent.addClass('hidden')
}
const showCartSamples = () => {
  cartSamplesComponent.removeClass('hidden')
}

const removeScentSamples = (event) => {
  if (!event.target.closest('[data-element="samples_add_to_cart"]')) return
  event.preventDefault()

  const slideItemComponent = event.target.closest('.slick-slide')
  const slideProductComponent = event.target.closest(
    '[data-element="samples_add_to_cart"]'
  )

  const variantId = slideProductComponent.getAttribute('data-variant')

  let selling_plan = ''
  let properties = {
    _pura_samples: true,
    _pura_hide_quantity: true,
  }

  getCart().then((cartData) => {
    if (!cartHasVariant(cartData, variantId)) {
      addVariantToCart(variantId, selling_plan, 1, properties).then(
        (cartData2) => {
          setTimeout(() => {
            cart.update(cartData2)
          }, 500)
        }
      )
      let sliderIndex = slideItemComponent.getAttribute('data-slick-index')
      $('[data-action="sample-items"]').slick('slickRemove', sliderIndex)
    }
  })
}

if (cartSamplesComponent.length > 0) {
  slideItem.addEventListener('click', removeScentSamples)
}

export { showScentSamples }
