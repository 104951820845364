import { cart } from './cart'

if (Pura.product?.id === 6985521496173) {
  let isObservingPopup = false

  const onRisePopupClose = () => {
    setTimeout(() => {
      cart.updateAndShow()
    }, 200)
    isObservingPopup = false
  }

  const isRisePopupElement = (node) =>
    node.nodeType === Node.ELEMENT_NODE &&
    node.id &&
    node.id.startsWith('Rise-popup-')

  const observePopupClosure = (popupElement) => {
    if (isObservingPopup) {
      return
    }
    isObservingPopup = true

    const popupStateObserver = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          const popup = mutation.target
          if (!popup.classList.contains('open')) {
            onRisePopupClose()
          }
        }
      })
    })

    const popup = popupElement.querySelector('.Rise-popup')
    if (popup) {
      popupStateObserver.observe(popup, {
        attributes: true,
        attributeFilter: ['class'],
      })
    }
  }

  const startObservingRisePopup = () => {
    const popupObserver = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          Array.from(mutation.addedNodes).forEach((addedNode) => {
            if (isRisePopupElement(addedNode)) {
              observePopupClosure(addedNode)
            }
          })

          Array.from(mutation.removedNodes).forEach((removedNode) => {
            if (isRisePopupElement(removedNode)) {
              onRisePopupClose()
            }
          })
        }
      })
    })

    popupObserver.observe(document.body, {
      childList: true,
      subtree: true,
    })
  }

  document.addEventListener('DOMContentLoaded', () => {
    startObservingRisePopup()
  })
}
