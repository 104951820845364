import $ from 'cash-dom'

if (window.Pura.isInApp) {
  $('#shopify-section-progress-bar-section').css('top', '110px')

  let hasHistory = false

  $(window).on('beforeunload', function () {
    hasHistory = true
  })

  $('[data-action="app-back"]').click(function () {
    history.back()
    setTimeout(function () {
      if (!hasHistory) {
        $('[data-action="app-back"]')
          .find('.svg-angle-left')
          .attr('stroke-opacity', 0.4)
      }
    }, 200)
    return false
  })

  $('[data-action="app-forward"]').click(function () {
    history.forward()
    setTimeout(function () {
      if (!hasHistory) {
        $('[data-action="app-forward"]')
          .find('.svg-angle-right')
          .attr('stroke-opacity', 0.4)
      }
    }, 200)
    return false
  })
}
