

function slideOpen(element, height) {

  if (element == null) return;

  let setHeightAuto = false;

  // Set height to auto
  element.style.height = 'auto'

  if (height == null || height == undefined){
    // Get the computed height of the element
    height = `${element.clientHeight}px`
    setHeightAuto = true;
  }
  
  // Set height to zero
  element.style.height = '0px'

  let duration = window.getComputedStyle(element, null).getPropertyValue("transition-duration");
  duration = parseInt(duration.replace('s', '') * 1000 );

  // After height is 0, then add actual height
  setTimeout(() => {
    element.style.height = height
  }, 10);

  if (setHeightAuto){
    setTimeout(function(){
        element.style.height = "auto"
    }, (duration + 15) );
  }

}


function slideClosed(element) {

  if (element == null) return;

  // Set the height
  element.style.height = `${element.clientHeight}px`

  setTimeout(() => {
    element.style.height = '0px'
    element.style.paddingTop = '0px'
    element.style.paddingBottom = '0px'
    element.style.overflow = 'hidden'
    element.style.borderWidth = '0px'
  }, 50)
}


export { slideOpen, slideClosed }
