
const getCollection = (url) => {
    return fetch( url , {
      crossDomain: true,
      credentials: 'same-origin',
    })
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response.text()
    })
    .catch(function(err) {  
        console.log('Failed to fetch page: ', err);  
    });
}

/*
const getCollectionProducts = (handle) => {
  return fetch(`/collections/${handle}/products.json`, {
    crossDomain: true,
    credentials: 'same-origin',
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response.json()
    })
    .catch((error) => {
      console.error(error)
    })
}
*/





export { getCollection }
