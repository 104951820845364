import { pushToDataLayer } from '.'

let gridView = 0
let items = []
let productsPerView

const setProductsPerView = () => {
  const width = window.innerWidth
  if (Pura.template.name == 'collection') {
    if (width <= 1024) productsPerView = 2
    else productsPerView = 3
  } else if (width <= 767) {
    productsPerView = 1
  } else if (width <= 1024) {
    productsPerView = 3
  } else {
    productsPerView = 4
  }
}

const dataLayerListEvent = (thumbnail) => {
  const productData = JSON.parse(
    thumbnail.querySelector(`script[type="application/json"]`).textContent
  )
  items.push(productData)
  gridView++

  if (gridView === productsPerView) {
    pushToDataLayer('view_item_list', {
      'view_item_list': {
        'ecommerce': {
          'items': items,
          'currency': 'USD',
        },
      },
    })
    gridView = 0
    items = []
  }
}

setProductsPerView()
window.addEventListener('resize', setProductsPerView)

const handleIntersect = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      dataLayerListEvent(entry.target)
      observer.unobserve(entry.target)
    }
  })
}
const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.5,
}

const observer = new IntersectionObserver(handleIntersect, options)
const observedThumbnails = new Set()

const observeProductThumbnail = (productGrid) => {
  productGrid.forEach((grid) => {
    const productThumbnail = grid.querySelectorAll(
      '[data-component="product-item"]'
    )
    productThumbnail.forEach((product) => {
      if (!observedThumbnails.has(product)) {
        observedThumbnails.add(product)
        observer.observe(product)
      }
    })
  })
}

const observeProducts = () => {
  setTimeout(() => {
    const productGrids = document.querySelectorAll(
      '[data-element="product-grid"]'
    )
    if (productGrids) observeProductThumbnail(productGrids)
  }, 1000)
}

const initializeProductObservation = () => {
  observeProducts()

  // observe when react load more btn is clicked
  document.body.addEventListener('click', (e) => {
    if (
      e.target &&
      e.target.getAttribute('data-action') === 'load-more-klevu-products'
    ) {
      observeProducts()
    }
  })

  // observe when react app is loaded
  window.addEventListener('reactAppLoaded', observeProducts)
}

// observe when page is loaded
document.addEventListener('DOMContentLoaded', initializeProductObservation)

export { observeProducts }
